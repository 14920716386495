import { Loader } from "@components/loader/loader"
import { defineMessage, t, Trans } from "@lingui/macro"
import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js"
import { useEffect, useRef, useState } from "react"

import BluePlus from "~/assets/BluePlus.svg"
import MastercardPure from "~/assets/MastercardPure.svg"
import PaypalIcon from "~/assets/PayPal.svg"
import SSLLogo from "~/assets/SSLLogo.svg"
import StripeLogo from "~/assets/StripeLogo.svg"
import VisaPure from "~/assets/VisaPure.svg"
import { ActionButton } from "~/components/buttons"
import { Button } from "~/components/buttons/choice-button/choice-button.styles"
import { Block } from "~/components2/atoms/Block"
// import { Button } from "~/components2/atoms/Button"
import { Flexed } from "~/components2/atoms/Flexed"
import { Input } from "~/components2/atoms/Input"
import { List } from "~/components2/atoms/List"
import { Reviews } from "~/components2/atoms/Reviews"
import { TextBody, TextWithIcon, Colored } from "~/components2/atoms/Text"

import { useWindowDimensions } from "~/hooks/use-window-dimensions"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { amplitudeService } from "~/services/amplitude"
import { DPaywallType } from "~/services/config/config-types"
import { i18nCustom } from "~/services/service/i18n-service"
import { PayPalButton } from "~/subscriptions-web/paypal/paypal-button"
import { usePaywall } from "../use-paywall"
import { AvailableOn } from "./atoms/available-on"
import { Copyright } from "./atoms/copyright"
import { PrePaywall } from "./atoms/prepaywall"
import { PrePaywallTime } from "./atoms/prepaywall-time"
import { PriceAfterTrial } from "./atoms/price-after-trial"
import { PriceToday } from "./atoms/price-today"
import { TrialInformation } from "./atoms/trial-info"
import { PaywallZipInput } from "./paywall-zip-input"
import { Promocode } from "./promocode"

import {
	Wrapper,
	ContentWrapper,
	Content,
	ExpandedArea,
	Markets,
	MediumBlock,
	benefits,
	reviewData,
	PaymentBubble,
	PaymentBlock,
	PaymentTitle,
	PaymentLogos,
	PaymentLabel,
	PaymentDataInput,
	PaymentMultiInputWrapper,
	ExpirationInput,
	CVCInput,
	TimerBox,
	PaymentError,
	ReviewsWrapper,
	TryTitle,
	PriceInfo,
	PaymentMethods,
	PaymentMethod,
	PayPalButtonWrapper,
	PayPalButtonContainer,
} from "./styles"

interface IProps {
	onNext?: (params?: any) => void
}

export const BravoPaywall = (props: IProps) => {
	const layout = useWindowLayout()
	const timerRef = useRef<HTMLDivElement>(null)
	const timeRef = useRef<HTMLDivElement>(null)
	const addressZipRef = useRef<HTMLInputElement>(null)
	const promocodeRef = useRef<HTMLDivElement>(null)

	const windowSize = useWindowDimensions()

	const [focused, setFocused] = useState(false)

	const {
		addressZip,
		product,
		paywallPrice,
		agreed,
		setAgreed,
		timeLeft,
		visibleTimer,
		submitProgress,
		errorToDisplay,
		stripeElementProps,
		paywallWeeklyPrice,
		createCardPaySubscription,
		createPayPalSubscription,
		handlePayPalApprove,
		handleScroll,
		logPaypalEvent,
		webPrepaywallType,
	} = usePaywall(DPaywallType.Bravo, addressZipRef, timeRef)

	useEffect(() => {
		if (timerRef.current) {
			if (visibleTimer) {
				timerRef.current.style.top = "12px"
			} else {
				timerRef.current.style.top = "-40px"
			}
		}
	}, [visibleTimer])

	useEffect(() => {
		if (agreed) {
			if (timeRef.current) {
				timeRef.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				})
			}
		}
	}, [agreed])

	const onClickStartTrial = () => {
		console.log("on click start trial")

		if (promocodeRef.current) {
			promocodeRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			})
		}
	}

	defineMessage({ message: "Days" })
	defineMessage({ message: "Day" })
	defineMessage({ message: "Paying..." })
	defineMessage({ message: "Start 3 Days Trial" })

	if (webPrepaywallType && product) {
		return (
			<Wrapper windowHeight={windowSize.height}>
				<ContentWrapper id="wrapper" onScroll={handleScroll}>
					<PrePaywall
						webPrepaywallType={webPrepaywallType}
						agreed={agreed}
						paywallPrice={paywallPrice!}
						setAgreed={setAgreed}
					/>

					<Content windowHeight={windowSize.height}>
						{agreed && (
							<ExpandedArea>
								<TimerBox ref={timerRef}>
									<p>
										<Trans>Plan reserved for</Trans>{" "}
									</p>
									<span>
										{Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
									</span>
								</TimerBox>
								<PrePaywallTime
									windowHeight={windowSize.height}
									webPrepaywallType={webPrepaywallType}
									ref={timeRef}
									timeLeft={timeLeft}
								/>

								<TryTitle layout={layout} size="big" margin="-40px 0 14px" centered>
									<Trans>Try</Trans> {product?.trial?.trialPeriodDays} <Trans>Trial</Trans>{" "}
									{(product?.trial?.trialPeriodDays ?? 0) > 1 ? i18nCustom._("Days") : i18nCustom._("Day")} <br />
									<Trans>with</Trans>{" "}
									<Colored color="">
										<Trans>Pora Course</Trans>
									</Colored>
								</TryTitle>

								<span id="benefits">
									<MediumBlock>
										<List items={benefits} />
									</MediumBlock>
								</span>

								<MediumBlock>
									<PriceToday product={product} paywallPrice={paywallPrice} />

									<PriceInfo>{product.labels.description}</PriceInfo>

									<PriceAfterTrial paywallWeeklyPrice={paywallWeeklyPrice} paywallPrice={paywallPrice} />

									<Promocode
										ref={promocodeRef}
										closed={({ openPromocode }) => (
											<TextWithIcon color="#5856D6" margin="24px 0 40px" icon={BluePlus} onClick={openPromocode}>
												<Trans>Add a promocode</Trans>
											</TextWithIcon>
										)}
										opened={({ promocode, onChangePromocode, applyPromocode }) => (
											<Block margin="20px 0 8px">
												<TextBody size="big" color="rgba(130,134,145,0.5)">
													<Trans>Promocode</Trans>
												</TextBody>
												<Input
													placeholder="6-digits code"
													value={promocode}
													type="other"
													onChange={onChangePromocode}
													width="100%"
													maxLength={6}
												/>
												<Button
													disabled={promocode.length === 0}
													color="blue"
													title="Apply"
													// width="100%"
													onClick={applyPromocode}
												>
													Apply
												</Button>
											</Block>
										)}
										applied={({ promocode }) => (
											<TextBody centered size="big" margin="20px 0 40px">
												<Trans>You've successfuly applied promocode</Trans>{" "}
												<Colored color="#5856d6">{promocode.toUpperCase()}</Colored>{" "}
												<Trans>and recieved one more month</Trans>{" "}
												<Colored color="#5856d6">
													<Trans>for free.</Trans>
												</Colored>{" "}
												<Trans>Thank you!</Trans>
											</TextBody>
										)}
									/>

									<PaymentMethods>
										<PaymentMethod active>
											<img alt="" src={VisaPure} />
											<img alt="" src={MastercardPure} />
										</PaymentMethod>
										<PayPalButtonWrapper>
											<img alt="" src={PaypalIcon} />
											<PayPalButtonContainer>
												<PayPalButton
													options={{
														vault: true,
														intent: "subscription",
														clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
													}}
													style={{ color: "white", size: "responsive" }}
													createSubscription={createPayPalSubscription}
													onApprove={handlePayPalApprove}
													onClick={logPaypalEvent}
												/>
											</PayPalButtonContainer>
										</PayPalButtonWrapper>
									</PaymentMethods>

									<form id="payment-form" onSubmit={createCardPaySubscription}>
										<PaymentBubble>
											<PaymentTitle>
												<Trans>Payment Info</Trans>
											</PaymentTitle>
											<PaymentLogos paywallType="bravo">
												<img alt="" src={StripeLogo} />
												<img alt="" src={SSLLogo} />
											</PaymentLogos>
											<PaymentBlock fullwidth>
												<PaymentLabel>
													<Trans>Card</Trans>
												</PaymentLabel>
												<PaymentDataInput>
													<CardNumberElement
														{...stripeElementProps}
														onFocus={() => {
															if (errorToDisplay) {
																setFocused(true)
															}
															amplitudeService.logEvent("[All] Paywall | Card number")
														}}
														onBlur={() => setFocused(false)}
													/>
												</PaymentDataInput>
											</PaymentBlock>
											<PaymentBlock fullwidth>
												<PaymentLabel>
													<Trans>Expires on</Trans>
												</PaymentLabel>
												<PaymentMultiInputWrapper>
													<ExpirationInput>
														<CardExpiryElement
															{...stripeElementProps}
															onFocus={() => {
																if (errorToDisplay) {
																	setFocused(true)
																}
																amplitudeService.logEvent("[All] Paywall | Expires date")
															}}
															onBlur={() => setFocused(false)}
														/>
													</ExpirationInput>
													<CVCInput>
														<CardCvcElement
															{...stripeElementProps}
															onFocus={() => {
																if (errorToDisplay) {
																	setFocused(true)
																}
																amplitudeService.logEvent("[All] Paywall | CVC")
															}}
															onBlur={() => setFocused(false)}
														/>
													</CVCInput>
													<PaywallZipInput
														ref={addressZipRef}
														addressZip={addressZip}
														onFocus={() => {
															if (errorToDisplay) {
																setFocused(true)
															}
														}}
														onBlur={() => setFocused(false)}
													/>
												</PaymentMultiInputWrapper>
											</PaymentBlock>
										</PaymentBubble>

										<Button
											type="submit"
											color="blue"
											// margin="24px 0 24px"
											title={submitProgress ? i18nCustom._("Paying...") : i18nCustom._("Start 3 Days Trial")}
											onClick={onClickStartTrial}
											// width="100%"
										>
											<Trans>Submit</Trans>
										</Button>

										{errorToDisplay && errorToDisplay.length && !focused && (
											<PaymentError>{errorToDisplay}</PaymentError>
										)}
									</form>

									<Markets />

									<TrialInformation
										paywallType={DPaywallType.Bravo}
										trialPeriodDays={product.trial.trialPeriodDays}
										paywallPrice={paywallPrice}
									/>

									<Flexed direction="row" justify="center">
										<PaymentLogos paywallType="bravo">
											<img alt="" src={StripeLogo} />
											<img alt="" src={SSLLogo} />
										</PaymentLogos>
									</Flexed>
								</MediumBlock>

								<AvailableOn />

								<TextBody centered size="big" color="#828691" margin="40px 0 16px">
									<Trans>Users about Us</Trans>
								</TextBody>

								<ReviewsWrapper>
									<Reviews items={reviewData} />
									<TextBody centered size="big" color="#828691" margin="24px 0 40px">
										<Trans>More than 100k 5-star reviews</Trans>
										<br />
										<Trans>in our app</Trans>
									</TextBody>
								</ReviewsWrapper>

								{/* <Trans> */}
								{/* <Button
									color="blue"
									title={t`Start 3 Days Trial`}
									// margin="0"
									// width="100%"
									onClick={onClickStartTrial}
								>
									Start 3 Days Trial
								</Button> */}
								<ActionButton size={layout} onClick={onClickStartTrial}>
									{product.labels.button}
								</ActionButton>
								{/* </Trans> */}

								<Copyright />
							</ExpandedArea>
						)}
					</Content>
				</ContentWrapper>
			</Wrapper>
		)
	} else return <Loader />
}
