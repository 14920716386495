import { defineMessage } from "@lingui/macro"
import { useElements } from "@stripe/react-stripe-js"
import { StripeElementChangeEvent } from "@stripe/stripe-js"
import { StripeCardNumberElementOptions } from "@stripe/stripe-js"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import smoothscroll from "smoothscroll-polyfill"
import styled from "styled-components"
import { IPrice, IPricesProps, Prices } from "~/components2/atoms/Prices"
import { useConfig } from "~/contexts/config-context"
import { airtableService } from "~/services/airtable/airtable"
import { amplitudeService } from "~/services/amplitude"
import { analytics } from "~/services/analytics"
import { SubscriptionValueDTO } from "~/services/config/config-dto-types"
import { DPaywallType } from "~/services/config/config-types"
import { cookies } from "~/services/cookies"
import { i18nCustom, navigatorLangCode } from "~/services/service/i18n-service"
import { useAuthContext } from "~/subscriptions-web/context"
import { ProgressStatus, useUserStatus } from "~/subscriptions-web/hooks"
import { OnApproveCallback } from "~/subscriptions-web/paypal/paypal-button"
import { paypalSubscriptionCreate } from "~/subscriptions-web/paypal/paypal-subscription-create"
import { stripeGetSubscriptionPrices, StripeSubscriptionPriceInfo } from "~/subscriptions-web/server-api"
//import { paddleSubscriptionCreate } from "~/subscriptions-web/server-api/paddle/paddle-subscription-create"
import { useStripeSubscription } from "~/subscriptions-web/stripe/use-stripe-subscription"

smoothscroll.polyfill()

type ProductLabels = {
	description: string
	button: string
}

type Goal = {
	id: string
	name: string
	type: "goal" | "area"
}

export const ChoosenItems = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	padding-right: 20px;

	margin-bottom: 12px;
`

export const Item = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px 12px;
	font-weight: 500;

	background: #6967ec;
	border-radius: 40px;

	margin: 4px 4px 4px 0;
	color: #ffffff;
`

export enum SCROLL_TO_PAYMENT_FORM_SOURCES {
	TIME_BLOCK = "time-block",
	PRICE_AFTER_TRIAL = "price-after-trial-block",
	PRICE_TODAY = "price-today-block",
}

export function usePaywall<Labels extends ProductLabels = ProductLabels & Partial<Record<string, string>>>(
	paywallType: DPaywallType,
	addressZipRef: React.RefObject<HTMLInputElement>,
	timeRef: React.RefObject<HTMLDivElement>
) {
	const remoteConfig = useConfig()
	const remoteProduct = remoteConfig?.webSubscription
	const paywallFirstScreen = remoteConfig?.paywallFirstScreen
	const webPrepaywallType = remoteConfig?.webPrepaywallType

	const paddleProductId = remoteProduct?.paddleProductId

	const splittedLangCode =
		navigatorLangCode === "zh-CN" || navigatorLangCode === "zh" ? "zh-CN" : navigatorLangCode.split("-")[0]

	const product: SubscriptionValueDTO = useMemo(
		() =>
			({
				...remoteProduct,
				labels:
					splittedLangCode !== "en" && splittedLangCode !== "en-GB"
						? remoteProduct?.locales[splittedLangCode] || remoteProduct?.labels
						: remoteProduct?.labels,
			} as SubscriptionValueDTO),
		[remoteProduct, splittedLangCode]
	)

	console.log("___PAYWALL_TYPE___", paywallType, remoteConfig, product)

	const logCheckoutEventOnce = useMemo(() => {
		let called = false
		return () => {
			if (called) return
			called = true
			if (remoteProduct) {
				analytics.track("checkout", remoteProduct)
			} else {
				throw new Error("Product is not loaded yet")
			}
		}
	}, [remoteProduct, paywallType])

	const [goals, setGoals] = useState<Airtable.Records<Goal> | null>(null)
	useEffect(() => {
		airtableService.tableFetcher<Goal>("goals").records.then((records) => {
			setGoals(records)
		})
	}, [])

	useEffect(() => {
		if (product && product !== null) {
			const getPaywallPrice = async () => {
				const { data, error } = await stripeGetSubscriptionPrices({
					priceIds: [product.priceId ?? ""],
					domain: process.env.REACT_APP_DOMAIN ?? "course",
				})
				if (!data || !data[0] || error) setPaywallPrice(null)
				else setPaywallPrice(data[0])
			}

			getPaywallPrice()
		}
	}, [product])

	const [paywallPrice, setPaywallPrice] = useState<StripeSubscriptionPriceInfo | null>(null)

	const {
		createStripeSubscription,
		stripePaymentProgress,
		stripeCreatePaymentRequest,
		stripePaymentAPI,
		stripeShowPaymentRequestDialog,
	} = useStripeSubscription()

	const { isProSubscriptionActive } = useUserStatus()

	const { setSubscriptionPaid } = useAuthContext()

	const submitProgress = useMemo(() => {
		const paymentInProgress = stripePaymentProgress.status === ProgressStatus.WORKING
		const paymentAccepted = stripePaymentProgress.status === ProgressStatus.DONE && !stripePaymentProgress.errorResponse
		const waitingForServerCallback = paymentAccepted && !isProSubscriptionActive
		return paymentInProgress || waitingForServerCallback
	}, [isProSubscriptionActive, stripePaymentProgress.errorResponse, stripePaymentProgress.status])

	const [agreed, setAgreed] = useState(false)
	useEffect(() => {
		if (paywallFirstScreen && paywallFirstScreen === "yes") {
			setAgreed(true)
		}
	}, [paywallFirstScreen])

	const [inputError, setInputError] = useState("")

	const addressZip = useState("")

	const [stripeRequestCreated, setStripeRequestCreated] = useState(false)

	const [timeLeft, setTimeLeft] = useState(900)
	const [visibleTimer, setVisibleTimer] = useState(false)

	const stripeElements = useElements()
	const stripeElementProps = useMemo(() => {
		return {
			options: {
				style: {
					base: {
						background: "#f6f6f6",
						borderRadius: "16px",
						color: "#32325d",
						fontFamily: "Helvetica",
						fontSize: "20px",
						fontWeight: "500",
						fontStyle: "normal",
						padding: "14px 20px",
						"::placeholder": {
							color: "#aab7c4",
						},
					},
					invalid: {
						color: "#fa755a",
						iconColor: "#fa755a",
					},
				},
			} as StripeCardNumberElementOptions,
			onChange(event: StripeElementChangeEvent) {
				console.log("handle change")
				if (event.complete && stripeElements) {
					const type: string = event.elementType
					if (type === "cardNumber") {
						stripeElements.getElement("cardExpiry")?.focus()
					} else if (type === "cardExpiry") {
						stripeElements.getElement("cardCvc")?.focus()
					} else if (type === "cardCvc") {
						addressZipRef.current?.focus()
					}
				}
				setInputError(event.error ? event.error.message : "")
			},
		}
	}, [addressZipRef, stripeElements])

	const errorToDisplay = useMemo(() => {
		if (stripePaymentProgress.errorResponse?.message) {
			return stripePaymentProgress.errorResponse.message
		}
		if (inputError) {
			return inputError
		}
		return undefined
	}, [inputError, stripePaymentProgress.errorResponse])

	/*
	const handleScroll = useCallback(() => {
		const topScroll = document.getElementById("wrapper")
		const benefitsTop = document.getElementById("benefits")

		if (topScroll && benefitsTop && topScroll.scrollTop >= benefitsTop.offsetTop) {
			setVisibleTimer(true)
		} else {
			setVisibleTimer(false)
		}
	}, [])
 */

	const handleScroll = useCallback(() => {}, [])

	const handleContinue = useCallback(() => {
		console.log("on click")
		amplitudeService.logEvent("[All] Button | Paywall first screen | Continue")

		setTimeout(() => {
			setAgreed(true)

			if (timeRef.current) {
				timeRef.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				})
			}
		}, 100)
	}, [timeRef])

	const ChosenAreas = useCallback(
		({ type, storedName }: { type: string; storedName: string }) => {
			if (!goals) return <h1>NO GOALS</h1>

			const goalsTyped = goals.filter((g) => g.fields.type === type) ?? []
			const ids = new Map(goalsTyped.map((g) => [g.fields.id, g.fields.name]))
			const stored = localStorage.getItem(storedName) ?? ""

			return (
				<ChoosenItems>
					{stored.split(", ").map((goal) => (
						<Item key={goal}>{ids.get(goal)}</Item>
					))}
				</ChoosenItems>
			)
		},
		[goals]
	)

	const PaywallPrices = useCallback(
		({
			prices,
			...props
		}: {
			prices: (subscription: typeof product, priceInfo: StripeSubscriptionPriceInfo) => IPrice[]
		} & Omit<IPricesProps, "items">) =>
			product && paywallPrice ? <Prices {...props} items={prices(product, paywallPrice)} /> : null,
		[paywallPrice, product]
	)

	const paywallWeeklyPrice = useCallback(() => {
		let amount: string
		defineMessage({ message: "week" })

		if (paywallPrice !== null) {
			switch (paywallPrice.interval) {
				case "day":
					amount = ((paywallPrice.amount / 100) * 7).toFixed(2)
					break
				case "week":
					amount = (paywallPrice.amount / 100).toFixed(2)
					break
				case "month":
					amount = (paywallPrice.amount / 100 / (4.3 * paywallPrice.intervalCount)).toFixed(2)
					break
				case "year":
					amount = (((paywallPrice.amount / 100) * 7) / 365).toFixed(2)
					break
				default:
					amount = (paywallPrice.amount / 100 / (4.3 * paywallPrice!.intervalCount)).toFixed(2)
			}
			switch (paywallPrice.currency.toUpperCase()) {
				case "USD":
					return `$${amount} / ${i18nCustom._("week")}`
				default:
					return `${amount} ${paywallPrice.currency.toUpperCase()} / ${i18nCustom._("week")}`
			}
		}
	}, [paywallPrice])

	useEffect(() => {
		if (agreed) {
			const interval = setInterval(() => {
				setTimeLeft((val) => {
					if (val <= 0) {
						clearInterval(interval)
						return val
					}
					return val - 1
				})
			}, 1000)
		}
	}, [agreed])

	useEffect(() => {
		if (agreed) {
			amplitudeService.logEvent("[All] Paywall")
		} else {
			amplitudeService.logEvent("[All] Paywall First screen")
		}
	}, [agreed])

	const createCardPaySubscription = useCallback(
		async (ev: any) => {
			try {
				if (product !== undefined) {
					ev.preventDefault()

					const [isSuccess, orderID] = await createStripeSubscription!({
						//price ids, amount, currenncy should be taken in firebase config, and then user can choose anyone here.
						// priceId: subscription.value.price_id,
						subscriptionInfo: {
							priceId: product.priceId,
							amount: product.trial.amount,
							currency: product.trial.currency,
						},
						userInfo: {
							email: localStorage.getItem("email") || "",
							fbc: cookies.get("_fbc"),
							fbp: cookies.get("_fbp"),
							amplitudeDeviceId: amplitudeService.deviceId,
							amplitudeUserId: amplitudeService.userId,
						},
						cardPay: {
							cardData: "CardNumberElement",
						},
					})

					// TODO: check start trial snapchat pixel and amplitude events
					amplitudeService.logEvent("[All] Button | Start trial", { source: "stripe", error: errorToDisplay })

					if (isSuccess) {
						amplitudeService.logEvent("[Subscription] Start trial", { source: "stripe" })
						analytics.track("purchase", {
							product: { ...product, trial: { ...product.trial } },
							orderID,
						})
					}
				}
			} catch {
				amplitudeService.logEvent("[All] Button | Start trial", { source: "stripe", error: errorToDisplay })
				console.log("subscription unsuccessful")
			}
		},
		[errorToDisplay, createStripeSubscription, product]
	)

	const createPayPalSubscription = useCallback(
		(data: any, actions: any) => {
			if (product && product !== null) {
				return actions.subscription.create({
					plan_id: product.planId, // Creates the subscription
				})

				/* const response = await paypalSubscriptionCreate({
					planId: product.planId ?? "",
					analytics: {
						email: localStorage.getItem("email") || "",
						fbc: cookies.get("_fbc"),
						fbp: cookies.get("_fbp"),
						amplitudeDeviceId: amplitudeService.deviceId,
						amplitudeUserId: amplitudeService.userId,
						domain: process.env.REACT_APP_DOMAIN ?? "course",
					},
				})
				console.error(`PAYPAL RESPONSE: ${JSON.stringify(response)}`)
				return response.id */
			}
		},
		[product]
	)

	const createPaddleSubscription = useCallback(async () => {
		const response = Promise.reject({ id: "MOCKED" })

		/*
		const response = await paddleSubscriptionCreate({
			sortIndex: 0,
			domain: process.env.REACT_APP_DOMAIN ?? "course",
			email: localStorage.getItem("email") || "",
			analytics: {
				fbc: cookies.get("_fbc") || "",
				fbp: cookies.get("_fbp") || "",
				amplitudeDeviceId: amplitudeService.deviceId || "",
				amplitudeUserId: amplitudeService.userId || "",
				// fbc: "",
				// fbp: "",
				// amplitudeDeviceId: "",
				// amplitudeUserId: "",
			},
		})
		*/
		console.log("+++PADDLE RESPONSE+++", response)
		console.error(`PADDLE RESPONSE: ${JSON.stringify(response)}`)
		return response
	}, [])

	const logScrollingToPaymentForm = useCallback((clickedElement: SCROLL_TO_PAYMENT_FORM_SOURCES) => {
		amplitudeService.logEvent("[All] Paywall | Scrolling to payment form", { source: clickedElement })
	}, [])

	const logPaypalEvent = useCallback(() => {
		logCheckoutEventOnce()
		amplitudeService.logEvent("[All] Paywall | Paypall")
		amplitudeService.logEvent("[All] Button | Start trial", { source: "paypal" })
	}, [logCheckoutEventOnce])

	const logPaypalError = useCallback((error: any) => {
		amplitudeService.logEvent("[All] Paywall | Paypall Error", { source: "paypal", error })
	}, [])

	const logPaypalCancel = useCallback((error: any) => {
		amplitudeService.logEvent("[All] Paywall | Paypall Cancel", { source: "paypal" })
	}, [])

	const handlePayPalApprove = useCallback<OnApproveCallback>(
		async (data, actions) => {
			console.log("[Subscription] Start trial", { source: "paypal", data })
			if (product && product !== null) {
				// Capture the funds from the transaction
				//await actions.order.capture()
				// Show a success message to your buyer
				amplitudeService.logEvent("[Subscription] Start trial", { source: "paypal" })
				analytics.track("purchase", {
					product: { ...product },
					orderID: data.orderID,
				})
				// OPTIONAL: Call your server to save the subscription
				return setSubscriptionPaid?.()
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}
		},
		[product, setSubscriptionPaid]
	)

	useEffect(() => {
		if (paywallPrice && product !== undefined && !stripeRequestCreated) {
			stripeCreatePaymentRequest({
				subscriptionInfo: {
					priceId: paywallPrice.priceId,
					amount: product.trial.amount ?? paywallPrice.amount,
					currency: product.trial.currency ?? paywallPrice.currency,
					label: "Pora subscription",
				},
				userInfo: {
					email: localStorage.getItem("email") ?? "",
					fbc: cookies.get("_fbc"),
					fbp: cookies.get("_fbp"),
					amplitudeDeviceId: amplitudeService.deviceId,
					amplitudeUserId: amplitudeService.userId,
				},
				paymentHandler: (isSuccess, paymentType, orderID) => {
					if (!isSuccess) {
						return
					}
					let source: "Payment Request API" | "Apple Pay" | "Google Pay" = "Payment Request API"
					if (paymentType.applePay) {
						source = "Apple Pay"
					} else if (paymentType.googlePay) {
						source = "Google Pay"
					}
					amplitudeService.logEvent("[Subscription] Start trial", { source })
					analytics.track("purchase", {
						product: { ...product, trial: { ...product.trial, amount: 5999 } },
						orderID,
					})
				},
			}).then(() => {
				setStripeRequestCreated(true)
			})
		}
	}, [paywallPrice, product, stripeCreatePaymentRequest, stripeRequestCreated])

	return {
		paywallFirstScreen,
		webPrepaywallType,
		product,
		paywallPrice,
		stripeShowPaymentRequestDialog,
		agreed,
		setAgreed,
		submitProgress,
		addressZip,
		timeLeft,
		visibleTimer,
		stripeElementProps,
		errorToDisplay,
		handleScroll,
		handleContinue,
		ChosenAreas,
		PaywallPrices,
		paywallWeeklyPrice,
		createCardPaySubscription,
		createPayPalSubscription,
		handlePayPalApprove,
		stripePaymentAPI,
		logPaypalEvent,
		logPaypalError,
		logPaypalCancel,
		logScrollingToPaymentForm,
		logCheckoutEventOnce,
		createPaddleSubscription,
		paddleProductId,
	}
}
