import { Trans } from "@lingui/macro"
import React from "react"
import styled, { css } from "styled-components"

import Facebook from "~/assets/Facebook.svg"
import Instagram from "~/assets/Instagram.svg"
import { TermsAndConditionsLink } from "../copyrights"

interface IFooterProps {
	minimal?: boolean
}

const StyledFooter = styled.div<{ minimal?: boolean }>`
	position: relative;
	width: 100%;
	height: ${({ minimal }) => (minimal ? "auto" : "202px")};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: ${({ minimal }) => minimal && "center"};

	box-sizing: border-box;

	padding: 0 20px;

	z-index: 100;

	margin-top: 24px;

	@media (orientation: landscape) {
		height: 116px;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;

		padding: 0 80px;
	}
`

const Socials = styled.div``

const Buttons = styled.div<{ minimal?: boolean }>`
	${({ minimal }) =>
		minimal
			? css`
					width: 300px !important;
					box-sizing: border-box;
					display: flex;
					flex-direction: row-reverse;
					justify-content: space-between;
					align-items: center;

					> p {
						margin: 0;
						color: #000 !important;
						font-weight: 500 !important;
						font-size: 18px !important;
					}
			  `
			: ""};
	@media (orientation: landscape) {
		/* border: 2px solid blue; */
		width: 100%;
	}

	> p {
		display: inline-block;
		margin-right: 8px;
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;

		letter-spacing: -0.03em;

		color: #1c1b47;

		@media (orientation: landscape) {
			margin: 0 0 8px;
		}

		@media (max-width: 320px) {
			font-size: 14px;
		}
	}
`

const Details = styled.div`
	> p {
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 26px;

		letter-spacing: -0.03em;

		color: #828691;
	}

	> div > p {
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 26px;

		letter-spacing: -0.03em;

		color: #828691;
	}
`

const DetailsBreak = styled.br`
	@media (orientation: landscape) {
		display: none;
	}
`

export function Footer(props: IFooterProps) {
	return (
		<StyledFooter minimal={props.minimal}>
			{!props.minimal && (
				<Socials>
					{/* FIXME */}
					<a href="https://www.instagram.com/pora/" target="_blank" rel="noreferrer">
						<img alt="" src={Instagram} />
					</a>
					<a href="https://www.facebook.com/profile.php?id=100079704866066" target="_blank" rel="noreferrer">
						<img alt="" src={Facebook} />
					</a>
				</Socials>
			)}
			<Details>
				<Buttons minimal={props.minimal}>
					<TermsAndConditionsLink />
					<p>
						<Trans>Privacy Policy</Trans>
					</p>
				</Buttons>
				{!props.minimal && (
					<>
						{/* Copyright ® 2021-2023 Pora. <DetailsBreak />
						All Rights Reserved */}
						<Trans>Copyright ® 2021-2023 Pora.</Trans> <DetailsBreak />
						<Trans>All Rights Reserved</Trans>
					</>
				)}
			</Details>
		</StyledFooter>
	)
}
