import { Elements } from "@stripe/react-stripe-js"
import React from "react"
import { useBeforeunload } from "react-beforeunload"
import { BrowserRouter } from "react-router-dom"
import { createGlobalStyle } from "styled-components"
import { ConfigProvider } from "~/contexts/config-context"
import { I18nServiceProvider } from "~/contexts/i18n-service-context"
import { QuestionsProvider } from "~/contexts/questions-context/questions-context"
import { YandexMetrikaProvider } from "~/contexts/yandex-metrika-context"
import { airtableService } from "~/services/airtable/airtable"
import { dConfigService } from "~/services/config/config-service"
// import { navigatorLangCode, i18nCustomService } from "~/services/service/i18n-service"
import { i18nCustomService } from "~/services/service/i18n-service"
import { yandexMetrikaService } from "~/services/service/yandex-metrika-service"
import { stripeAppPromise } from "~/subscriptions-web/apps/stripe-app"
import { AuthProvider } from "~/subscriptions-web/context"
import { PreferenceProvider } from "~/subscriptions-web/context"
import { App } from "./organisms/App"

const GlobalStyle = createGlobalStyle`
	html {
		height: -webkit-fill-available;
	}
	body {
		margin: 0;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
			'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
			sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		min-height: 100vh;
		/* mobile viewport bug fix */
		min-height: -webkit-fill-available;
	}
	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
			monospace;
	}
`

export function Root() {
	useBeforeunload(() => "You'll lose your data!")

	// dConfigService.fetchConfig("ru1")

	return (
		<React.StrictMode>
			<GlobalStyle />
			<I18nServiceProvider service={i18nCustomService}>
				<ConfigProvider service={dConfigService}>
					<BrowserRouter>
						<QuestionsProvider service={airtableService as any}>
							<AuthProvider>
								<PreferenceProvider>
									<Elements stripe={stripeAppPromise}>
										<YandexMetrikaProvider service={yandexMetrikaService}>
											<App />
										</YandexMetrikaProvider>
									</Elements>
								</PreferenceProvider>
							</AuthProvider>
						</QuestionsProvider>
					</BrowserRouter>
				</ConfigProvider>
			</I18nServiceProvider>
		</React.StrictMode>
	)
}
