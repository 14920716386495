import { Stripe } from "@stripe/stripe-js"

export interface handlePaymentThatRequiresCustomerActionParams {
	paymentIntent: any
	paymentMethodId: string
	isRetry: boolean
}

/**
 * Some payment methods require a customer to be on session
 * to complete the payment process. Checks the status of the
 * payment intent to handle these actions.
 * In case of error throws an error.
 */
export const handlePaymentThatRequiresCustomerAction = async (
	stripe: Stripe,
	{ paymentIntent, paymentMethodId, isRetry }: handlePaymentThatRequiresCustomerActionParams
) => {
	try {
		// If it's a first payment attempt, the payment intent is on the subscription latest invoice.
		// If it's a retry, the payment intent will be on the invoice itself.
		//const paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

		if (
			paymentIntent.status === "requires_action" ||
			(isRetry === true && paymentIntent.status === "requires_payment_method")
		) {
			const result = await stripe.confirmCardPayment(paymentIntent.client_secret, {
				payment_method: paymentMethodId,
			})

			if (result.error || !result.paymentIntent) {
				// The card was declined (i.e. insufficient funds, card has expired, etc).
				// Start code flow to handle updating the payment details.
				// Display error message in your UI.
				throw new Error("Card has been declined.")
			}

			if (result.paymentIntent.status === "succeeded") {
				return true
			}

			// alert("handlePaymentThatRequiresCustomerActionParams - Unexpected branch????")
			console.log("handlePaymentThatRequiresCustomerActionParams - Unexpected branch????")
			return false
		} else {
			// No customer action needed.
			return true
		}
	} catch (ex) {
		console.error(ex)
		throw ex
	}
}
