import { Trans } from "@lingui/macro"
import { forwardRef, ReactElement, Ref } from "react"

import ArrowDown from "~/assets/ArrowDown.svg"
import { Colored, TextBody, TextHead } from "~/components2/atoms/Text"
import { DPrepaywallType } from "~/services/config/config-types"

import { MobileBreak, TimeBlock, TimeWrapper } from "../styles"

export interface PrePaywallTimeProps {
	windowHeight: number
	webPrepaywallType: DPrepaywallType
	timeLeft: number
	onClick?: () => void
}

const noop = () => void 0

export const PrePaywallTime = forwardRef<HTMLDivElement, PrePaywallTimeProps>(
	(
		{ windowHeight, webPrepaywallType, timeLeft, onClick = noop }: PrePaywallTimeProps,
		ref: Ref<HTMLDivElement>
	): ReactElement => {
		return (
			<TimeWrapper
				ref={ref}
				windowHeight={windowHeight}
				onClick={onClick}
				isDark={webPrepaywallType === DPrepaywallType.Bravo}
			>
				<TimeBlock>
					<TextBody size="big">
						<Trans>Your personal course has been reserved for the next</Trans>{" "}
						<Colored color="#6967ec">
							<MobileBreak />
							<Trans>15 minutes!</Trans>
						</Colored>
					</TextBody>
					<TextHead size="big" color="#5856EA">
						{Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
					</TextHead>
					<span style={{ display: "flex", flexDirection: "row" }}>
						<TextBody size="big" color="#828691">
							<Trans>Keep it to yourself below</Trans>{" "}
						</TextBody>
						<img alt="" src={ArrowDown} />
					</span>
				</TimeBlock>
			</TimeWrapper>
		)
	}
)
