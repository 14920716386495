import firebase from "firebase/app"
/*
// Required for side-effects
import "firebase/auth"
import "firebase/remote-config"
require("firebase/functions")
require("firebase/firestore")
let firebaseConfig: any = {
	apiKey: process.env.REACT_APP_FIREBASE_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

if (process.env.REACT_APP_FIREBASE_DATABASE) {
	firebaseConfig = {
		...firebaseConfig,
		databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
	}
}

if (process.env.REACT_APP_FIREBASE_MEASUREMENT_ID) {
	firebaseConfig = {
		...firebaseConfig,
		measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
	}
}
*/

//export const firebaseApp = firebase.initializeApp(firebaseConfig)

let uid: string | undefined = undefined
let email: string = ""

const createUser = () =>
	({
		uid,
		email,
		isAnonymous: true,
		emailVerified: false,
		getIdToken: async () => "ID_TOKEN",
		getIdTokenResult: async () => ({ token: "ID_TOKEN" }),
	} as unknown as firebase.User)

const fetchUserUid = async (): Promise<string> => {
	const url = new URL(`${process.env.REACT_APP_API_PREFIX}/interview/get_user_id`)

	const res = await fetch(String(url))
	return await res.text()
}

let loading = false
export const firebaseApp = {
	auth: (): firebase.auth.Auth => {
		/* FIXME MOCK get user_id from backend */
		return {
			async signInAnonymously() {
				console.warn("===== signInAnonymously =====")
				if (uid) {
					return createUser()
				}

				const sessionUid = sessionStorage.getItem("UID")
				if (sessionUid) {
					uid = sessionUid
					return createUser()
				}

				uid = await fetchUserUid()
				sessionStorage.setItem("UID", uid)
				return createUser()
			},

			currentUser: createUser(),
		} as unknown as firebase.auth.Auth
	},
}

/*
export const firebaseFunctions = firebaseApp.functions()
export const firestore = firebaseApp.firestore()
export const firebaseRemoteConfig = firebaseApp.remoteConfig()
 */
