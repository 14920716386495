import { ChoiceButton } from "@components/buttons"
import { OverlapLayout } from "@components/layouts"
import { forwardRef, PropsWithChildren, useCallback } from "react"
import { useWindowLayout } from "~/hooks/use-window-layout"

import { Wrapper, Label } from "./select-with-picture-screen.styles"

export interface ISelectWithPictureScreenProps {
	label: string
	imageSrc: string
	options: string[]
	addSkip?: boolean
	onNext: (s: any) => void
}

export const SelectWithPictureScreen = forwardRef<
	unknown,
	PropsWithChildren<ISelectWithPictureScreenProps & JSX.IntrinsicElements["div"]>
>(({ onNext, addSkip = false, ...rest }, ref: any) => {
	const size = useWindowLayout()
	const handleClick = useCallback(
		(option: any, index: number) => {
			return () => {
				let skip = {}
				if (addSkip) {
					skip = { skip: index === 0 ? 1 : 0 }
				}
				onNext({ value: [option], ...skip })
			}
		},
		[onNext, addSkip]
	)

	return (
		<Wrapper ref={ref} size={size}>
			<Label size={size}>{rest.label}</Label>
			<OverlapLayout withAdditionalMargin={false} src={rest.imageSrc} size={size}>
				{rest.options.map((option, index) => (
					<ChoiceButton key={option} size={size} onClick={handleClick(option, index)}>
						{option}
					</ChoiceButton>
				))}
			</OverlapLayout>
		</Wrapper>
	)
})
