import { color, background } from "@components/shared/colors"
import { LayoutType } from "@components/shared/layouts"
import { typography } from "@components/shared/typography"
import styled from "styled-components"

export const Wrapper = styled.div<{ size: keyof typeof LayoutType }>`
	position: absolute;
	width: 100%;
	height: ${({ size }) => (size === "Mobile" ? "100%" : "calc(100% - 64px)")};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	box-sizing: border-box;

	background: ${background.app};

	padding-bottom: ${({ size }) => (size === "Mobile" ? 20 : 0)}px;
`

export const Top = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 20px;
	box-sizing: border-box;
`

export const Bottom = styled.div`
	position: relative;
	bottom: 0;
	left: 0;
	width: 100%;
	height: auto;

	display: flex;
	flex-direction: column;
	align-items: center;

	box-sizing: border-box;
`

export const Title = styled.p<{ size: keyof typeof LayoutType }>`
	width: ${({ size }) => (size === "Mobile" ? "100%" : "400px")};

	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.extrabold};
	font-size: ${({ size }) => (size === "Mobile" ? typography.fontSize.hm2 : typography.fontSize.h2)}px;
	line-height: 110%;

	text-align: center;
	letter-spacing: ${typography.letterSpacing.h}em;

	color: ${color.black};

	/* margin: ${({ size }) => (size === "Mobile" ? "12px 0 24px" : "40px 0 32px")}; */

	/* margin: 40px 0 0; */
	margin: 12px 0 0;
`

export const Description = styled.small<{ size: keyof typeof LayoutType }>`
	width: ${({ size }) => (size === "Mobile" ? "100%" : "400px")};

	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.bold};
	/* 24/34 */
	font-size: ${({ size }) => (size === "Mobile" ? typography.fontSize.bm1 : typography.fontSize.b1)}px;
	line-height: ${({ size }) => (size === "Mobile" ? typography.fontSize.hm2 : typography.lineSize.b1)}px;
	/* or 142% */

	text-align: center;
	letter-spacing: ${typography.letterSpacing.b}em;

	color: ${color.smoke};

	/* margin: 16px 0 40px; */

	margin: ${({ size }) => (size === "Mobile" ? "12px 0 24px" : "16px 0 40px")};
`

export const MobileButton = styled.div<{ size: keyof typeof LayoutType }>`
	margin: 20px 0;
`

export const DesktopButton = styled.div<{ size: keyof typeof LayoutType }>`
	margin: 40px 0 24px;
`
