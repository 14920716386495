import { color, background } from "@components/shared/colors"
import { LayoutType } from "@components/shared/layouts"
import { spacing } from "@components/shared/spacing"
import { typography } from "@components/shared/typography"
import styled, { css } from "styled-components"
import { Footer } from "../../components/footer"

type TSpace = "center" | "space-between" | "space-evenly" | "space-around" | "flex-start" | "flex-end"

export const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background-color: ${background.app};
	box-sizing: border-box;

	z-index: 50;
`

export const DownloadWrapper = styled.div`
	position: relative;
	top: 80px;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	padding-bottom: 0;
	box-sizing: border-box;

	transition: left 0.4s;
	background-color: ${background.app};
`

export const ScreenshotWrapper = styled.div`
	position: relative;
	width: 100%;

	> img {
		width: 100%;
		height: auto;
	}
`

export const Content = styled.div`
	position: relative;
	width: 100%;
	height: auto;

	padding: 0 ${spacing.padding.horizontalSmall}px 24px;

	display: flex;
	flex-direction: column;
	align-items: center;

	box-sizing: border-box;

	> p {
		margin: 24px 12px;
		text-align: center;
	}
`

export const DownloadContent = styled(Content)<{ size: keyof typeof LayoutType }>`
	position: relative;
	width: 100%;
	height: auto;

	${({ size }) =>
		size === "Desktop" &&
		css`
			flex-direction: row;
			justify-content: center;
		`};
`

export const Flexed = styled.div<{
	direction?: "row" | "column"
	justify?: TSpace
	align?: TSpace
	width?: string
	height?: string
}>`
	display: flex;
	min-width: ${({ width }) => width || "100%"};
	height: ${({ height }) => height || "auto"};

	${({ direction, justify, align }) => css`
		flex-direction: ${direction || "row"};
		justify-content: ${justify || "space-between"};
		align-items: ${align || "center"};
	`};
`

export const Title = styled.p<{ size: keyof typeof LayoutType }>`
	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.extrabold};
	font-size: ${({ size }) => (size === "Mobile" ? typography.fontSize.hm2 : typography.fontSize.h2)}px;
	line-height: 110%;

	letter-spacing: ${typography.letterSpacing.h}em;

	color: ${color.black};

	margin: 12px 0;
`

export const Description = styled.p<{ size: keyof typeof LayoutType }>`
	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.bold};
	font-size: ${({ size }) => (size === "Mobile" ? typography.fontSize.bm1 : typography.fontSize.b1)}px;
	line-height: ${({ size }) => (size === "Mobile" ? typography.lineSize.bm1 : typography.lineSize.h4)}px;

	letter-spacing: ${typography.letterSpacing.b}em;

	color: ${color.smoke};

	margin: 0 0 16px 0;
`

export const Block = styled.div<{ size: keyof typeof LayoutType }>`
	position: relative;
	width: ${({ size }) => (size === "Mobile" ? "100%" : "480px")};
	height: auto;
`

export const BlockNumber = styled.div`
	width: 40px;
	height: 40px;
	left: 20px;
	top: 80px;

	border-radius: 50%;

	background: ${background.indigo};

	color: ${color.white};

	font-family: ${typography.family.inter};
	font-weight: ${typography.fontWeight.extrabold};
	font-size: ${typography.fontSize.b1}px;
	line-height: ${typography.lineSize.b3}px;
	letter-spacing: -4%;

	display: flex;
	justify-content: center;
	align-items: center;
`

export const DownloadButton = styled.a<{ size: keyof typeof LayoutType }>`
	width: 100%;
	height: 56px;
	background: ${background.black};
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: ${spacing.borderRadius.small}px;
	margin: 12px 0;

	text-decoration: none;

	margin: ${({ size }) => size === "Desktop" && "12px 0 0"};

	> span {
		position: relative;
		margin-left: 40%;

		> img {
			position: absolute;
			left: -52px;
		}

		${({ size }) =>
			size === "Desktop" &&
			css`
				margin-left: 64px;

				> img {
					left: -50px;
				}
			`};
	}
`

export const Line = styled.div`
	width: 4px;
	height: 40px;

	margin: 12px 0 16px 16px;

	background: ${background.smoke};
	opacity: 0.2;
	border-radius: 2px;
`

export const Email = styled.span`
	color: #5856d6;
	overflow-wrap: anywhere;
`

export const Sub = styled.p`
	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.bold};
	font-size: ${typography.fontSize.bm3}px;
	line-height: 19px;
	letter-spacing: ${typography.letterSpacing.b}em;

	color: #9ca1ac;

	margin: 0;
`

export const Appstore = styled.p`
	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.bold};
	font-size: ${typography.fontSize.bm1}px;
	line-height: ${typography.lineSize.bm3}px;
	letter-spacing: ${typography.letterSpacing.b}em;

	color: ${color.white};

	margin: 0;
`

export const DesktopDownloadButtons = styled.div`
	padding: 0 24px;
	margin: 0;

	width: 100%;
	height: auto;
	min-height: auto;

	position: relative;

	background: ${background.white};

	border-radius: ${spacing.borderRadius.small}px;
	box-sizing: border-box;

	overflow: hidden;

	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.05);

	> div > div {
		box-sizing: border-box;
		padding: ${spacing.padding.verticalHuge}px 0;
		margin: 0;

		&:first-child {
			border-right: 2px solid #f6f6f6;
			padding-right: ${spacing.padding.horizontalExtraSmall}px;
		}

		&:last-child {
			padding-left: ${spacing.padding.horizontalExtraSmall}px;
		}
	}
`
export const MobileDownloadButtons = styled(Flexed)``

export const QR = styled.img`
	width: 60%;
`

export const FooterWrapper = styled(Footer)`
	position: relative;
`
