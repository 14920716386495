import { Loader } from "@components/loader/loader"
import { defineMessage, t, Trans } from "@lingui/macro"
import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js"
import { useEffect, useRef, useCallback } from "react"

import AmericanExpressIcon from "~/assets/AmericanExpress.png"
import BluePlus from "~/assets/BluePlus.svg"
import DiscoverIcon from "~/assets/Discover.png"
import MastercardIcon from "~/assets/Mastercard.png"
import SSLLogo from "~/assets/SSLLogo.svg"
import StripeLogo from "~/assets/StripeLogo.svg"
import VisaIcon from "~/assets/Visa.png"
import { Block } from "~/components2/atoms/Block"
import { Bubble } from "~/components2/atoms/Bubble"
import { Button } from "~/components2/atoms/Button"
import { Flexed } from "~/components2/atoms/Flexed"
import { Input } from "~/components2/atoms/Input"
import { List } from "~/components2/atoms/List"
import { Reviews } from "~/components2/atoms/Reviews"
import { TextBody, TextWithIcon, Colored } from "~/components2/atoms/Text"

import { useWindowDimensions } from "~/hooks/use-window-dimensions"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { amplitudeService } from "~/services/amplitude"
import { DPaywallType } from "~/services/config/config-types"
import { i18nCustom } from "~/services/service/i18n-service"
import { usePaywall } from "../use-paywall"
import { AvailableOn } from "./atoms/available-on"
import { Copyright } from "./atoms/copyright"
import { PrePaywall } from "./atoms/prepaywall"
import { PrePaywallTime } from "./atoms/prepaywall-time"
import { TrialInformation } from "./atoms/trial-info"
import { PaywallZipInput } from "./paywall-zip-input"

import { Promocode } from "./promocode"
import {
	Wrapper,
	ContentWrapper,
	Content,
	ExpandedArea,
	MediumBlock,
	benefits,
	reviewData,
	PaymentBubble,
	PaymentBlock,
	PaymentTitle,
	PaymentLogos,
	PaymentLabel,
	PaymentDataInput,
	PaymentMultiInputWrapper,
	ExpirationInput,
	CVCInput,
	TimerBox,
	PaymentError,
	ReviewsWrapper,
	TryTitle,
} from "./styles"

interface IProps {
	onNext?: (params?: any) => void
}

export const AlphaPaywall = (props: IProps) => {
	const layout = useWindowLayout()
	const timerRef = useRef<HTMLDivElement>(null)
	const timeRef = useRef<HTMLDivElement>(null)
	const addressZipRef = useRef<HTMLInputElement>(null)
	const promocodeRef = useRef<HTMLDivElement>(null)

	const windowSize = useWindowDimensions()

	const {
		agreed,
		setAgreed,
		timeLeft,
		addressZip,
		visibleTimer,
		submitProgress,
		errorToDisplay,
		stripeElementProps,
		PaywallPrices,
		createCardPaySubscription,
		handleScroll,
		webPrepaywallType,
		paywallPrice,
	} = usePaywall(DPaywallType.Alpha, addressZipRef, timeRef)

	const onClickStartTrial = useCallback(() => {
		console.log("on click start trial")

		if (promocodeRef.current) {
			promocodeRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			})
		}
	}, [])

	useEffect(() => {
		if (agreed) {
			if (timeRef.current) {
				timeRef.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				})
			}
		}
	}, [agreed])

	useEffect(() => {
		if (timerRef.current) {
			timerRef.current.style.top = "12px"
		}
	}, [visibleTimer])

	defineMessage({ message: "Days" })
	defineMessage({ message: "Day" })
	defineMessage({ message: "trial unlocked" })
	defineMessage({ message: "Month" })
	defineMessage({ message: "Plan after trial" })
	defineMessage({ message: "Total today" })
	defineMessage({ message: "Paying..." })
	defineMessage({ message: "Start 3 Days Trial" })

	const prices = useCallback(
		(subscription, priceInfo) => [
			{
				title:
					`${subscription.trial.trial_period_days}-${
						subscription.trial.trial_period_days > 1
							? i18nCustom._("Days").toLowerCase()
							: i18nCustom._("Day").toLowerCase()
					}` + i18nCustom._("trial unlocked"),
				newPrice: `$${subscription.trial.amount / 100}`,
				oldPrice: `$${Math.round(priceInfo.amount / 100).toFixed(2)}`,
			},
			{
				title: "1-" + i18nCustom._("Month") + i18nCustom._("Plan after trial"),
				newPrice: `$${Math.round(priceInfo.amount / 100).toFixed(2)}`,
				oldPrice: `$${Math.round(priceInfo.amount / 100).toFixed(2)}`,
			},
		],
		[]
	)
	const totalPrices = useCallback(
		(subscription, priceInfo) => [
			{
				title: i18nCustom._("Total today"),
				newPrice: `$${subscription.trial.amount / 100}`,
				oldPrice: `$${Math.round(priceInfo.amount / 100).toFixed(2)}`,
			},
		],
		[]
	)

	if (webPrepaywallType) {
		return (
			<Wrapper windowHeight={windowSize.height}>
				<ContentWrapper id="wrapper" onScroll={handleScroll}>
					<PrePaywall
						webPrepaywallType={webPrepaywallType}
						agreed={agreed}
						paywallPrice={paywallPrice!}
						setAgreed={setAgreed}
					/>

					<Content windowHeight={windowSize.height}>
						{agreed && (
							<ExpandedArea>
								<TimerBox ref={timerRef}>
									<p>
										<Trans>Plan reserved for</Trans>{" "}
									</p>
									<span>
										{Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
									</span>
								</TimerBox>
								<PrePaywallTime
									windowHeight={windowSize.height}
									webPrepaywallType={webPrepaywallType}
									ref={timeRef}
									timeLeft={timeLeft}
								/>{" "}
								<TryTitle layout={layout} size="big" margin="-40px 0 14px" centered>
									<Trans>Try 3 Trial Days</Trans> <br />
									<Trans>with</Trans>{" "}
									<Colored color="">
										<Trans>Pora Course</Trans>
									</Colored>
								</TryTitle>
								<span id="benefits">
									<MediumBlock>
										<List items={benefits} />
									</MediumBlock>
								</span>
								<MediumBlock>
									<Bubble padding="0" margin="40px 0 12px">
										<PaywallPrices prices={prices} />
									</Bubble>
									<Bubble padding="0">
										<PaywallPrices color="#6967ec" prices={totalPrices} />
									</Bubble>
									<Promocode
										ref={promocodeRef}
										closed={({ openPromocode }) => (
											<TextWithIcon color="#5856D6" margin="24px 0 40px" icon={BluePlus} onClick={openPromocode}>
												<Trans>Add a promocode</Trans>
											</TextWithIcon>
										)}
										opened={({ promocode, onChangePromocode, applyPromocode }) => (
											<Block margin="20px 0 8px">
												<TextBody size="big" color="rgba(130,134,145,0.5)">
													<Trans>Promocode</Trans>
												</TextBody>
												<Input
													placeholder="6-digits code"
													value={promocode}
													type="other"
													onChange={onChangePromocode}
													width="100%"
													maxLength={6}
												/>
												<Button
													disabled={promocode.length === 0}
													color="blue"
													title="Apply"
													width="100%"
													onClick={applyPromocode}
												/>
											</Block>
										)}
										applied={({ promocode }) => (
											<TextBody centered size="big" margin="20px 0 40px">
												<Trans>You've successfuly applied promocode</Trans>{" "}
												<Colored color="#5856d6">{promocode.toUpperCase()}</Colored>{" "}
												<Trans>and recieved one more month</Trans>{" "}
												<Colored color="#5856d6">
													<Trans>for free.</Trans>
												</Colored>{" "}
												<Trans>Thank you!</Trans>
											</TextBody>
										)}
									/>

									<form id="payment-form" onSubmit={createCardPaySubscription}>
										<PaymentBubble>
											<PaymentTitle>
												<Trans>Payment Info</Trans>
											</PaymentTitle>
											<PaymentLogos>
												<img alt="" src={VisaIcon} />
												<img alt="" src={MastercardIcon} />
												<img alt="" src={AmericanExpressIcon} />
												<img alt="" src={DiscoverIcon} />
											</PaymentLogos>
											<PaymentBlock fullwidth>
												<PaymentLabel>
													<Trans>Card</Trans>
												</PaymentLabel>
												<PaymentDataInput>
													<CardNumberElement
														{...stripeElementProps}
														onFocus={() => {
															amplitudeService.logEvent("[All] Paywall | Card number")
														}}
													/>
												</PaymentDataInput>
											</PaymentBlock>
											<PaymentBlock fullwidth>
												<PaymentLabel>
													<Trans>Expires on</Trans>
												</PaymentLabel>
												<PaymentMultiInputWrapper>
													<ExpirationInput>
														<CardExpiryElement
															{...stripeElementProps}
															onFocus={() => {
																amplitudeService.logEvent("[All] Paywall | Expires date")
															}}
														/>
													</ExpirationInput>
													<CVCInput>
														<CardCvcElement
															{...stripeElementProps}
															onFocus={() => {
																amplitudeService.logEvent("[All] Paywall | CVC")
															}}
														/>
													</CVCInput>
													<PaywallZipInput ref={addressZipRef} addressZip={addressZip} />
												</PaymentMultiInputWrapper>
											</PaymentBlock>
										</PaymentBubble>

										<Button
											type="submit"
											color="blue"
											margin="24px 0 24px"
											title={submitProgress ? i18nCustom._("Paying...") : i18nCustom._("Start 3 Days Trial")}
											width="100%"
										/>

										{errorToDisplay && <PaymentError>{errorToDisplay}</PaymentError>}
									</form>

									<TrialInformation
										paywallType={DPaywallType.Alpha}
										trialPeriodDays={3} // TODO: почему хард кор??? убрать!!!
										paywallPrice={paywallPrice}
									/>
									<br />
									<Flexed direction="row" justify="center">
										<PaymentLogos paywallType="bravo">
											<img alt="" src={StripeLogo} />
											<img alt="" src={SSLLogo} />
										</PaymentLogos>
									</Flexed>
								</MediumBlock>
								<AvailableOn />
								<TextBody centered size="big" color="#828691" margin="40px 0 16px">
									<Trans>Users about Us</Trans>
								</TextBody>
								<ReviewsWrapper>
									<Reviews items={reviewData} />
									<TextBody centered size="big" color="#828691" margin="24px 0 40px">
										<Trans>More than 100k 5-star reviews</Trans>
										<br />
										<Trans>in our app</Trans>
									</TextBody>
								</ReviewsWrapper>
								<Button
									color="blue"
									title={i18nCustom._("Start 3 Days Trial")}
									margin="0"
									width="100%"
									onClick={onClickStartTrial}
								/>
								<Copyright />
							</ExpandedArea>
						)}
					</Content>
				</ContentWrapper>
			</Wrapper>
		)
	} else return <Loader />
}
