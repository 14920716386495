/* import { firebaseRemoteConfig } from "../firebase/firebase-app" */
import { ABTestParam, IConfigDTO, IConfigDTOService } from "./config-dto-types"
import { mockedConfig } from "./default-config.mock"

const EConfigFirebaseToDTO: Record<string, keyof IConfigDTO> = {
	web_landing_type_v3: "webLandingType",
	web_onboarding_type_v3: "webOnboardingType",
	web_onboarding_type_v3_man: "webOnboardingTypeMan",
	web_prepaywall_type_v3: "webPrepaywallType",
	web_paywall_type_v3: "webPaywallType",
	web_subscription_v3: "webSubscription",
	web_ab_test_v3: "webAbTest",
	paywall_first_screen_v3: "paywallFirstScreen",
}

const EConfigDTOToFirebase: { [key in keyof IConfigDTO]: string } = {
	webLandingType: "web_landing_type_v3",
	webOnboardingType: "web_onboarding_type_v3",
	webOnboardingTypeMan: "web_onboarding_type_v3_man",
	webPrepaywallType: "web_prepaywall_type_v3",
	webPaywallType: "web_paywall_type_v3",
	webSubscription: "web_subscription_v3",
	webAbTest: "web_ab_test_v3",
	paywallFirstScreen: "paywall_first_screen_v3",
}

const defaultSConfigDTO: IConfigDTO = {
	webLandingType: [],
	webOnboardingType: [],
	webOnboardingTypeMan: [],
	webPrepaywallType: [],
	webPaywallType: [],
	webSubscription: [],
	webAbTest: [],
	paywallFirstScreen: [],
}
/* MOCKED
export class ConfigDTOService implements IConfigDTOService {
	public async fetchConfig() {
		// set firebase remote config fetch settings
		firebaseRemoteConfig.settings = {
			minimumFetchIntervalMillis: 1000 * 5, // 5 seconds
			fetchTimeoutMillis: 1000 * 60, // 1 minute
		}

		// set default values for the case unsuccesfull fetch
		Object.keys(defaultSConfigDTO).forEach((keyDTO) => {
			const keyFirebase = EConfigDTOToFirebase[keyDTO as keyof IConfigDTO]
			firebaseRemoteConfig.defaultConfig[keyFirebase] = JSON.stringify(defaultSConfigDTO[keyDTO as keyof IConfigDTO])
		})

		// Fetch and cache configuration from the Remote Config service.
		await firebaseRemoteConfig.fetch()
		if (firebaseRemoteConfig.lastFetchStatus !== "success") {
			console.warn("Failed to fetch remote config:", firebaseRemoteConfig.lastFetchStatus)
		}

		// activate remote values
		const active = await firebaseRemoteConfig.activate()
		if (!active) {
			console.warn("Failed to activate remote config")
		}

		// get values from remote config
		const valuesMap = firebaseRemoteConfig.getAll()

		// update defaultSConfigDTO values
		Object.keys(defaultSConfigDTO).forEach((key) => {
			const keyDTO = key as keyof IConfigDTO
			const keyFirebase = EConfigDTOToFirebase[keyDTO]
			const valueFirebase = valuesMap[keyFirebase]
			const objValue = JSON.parse(valueFirebase.asString())
			defaultSConfigDTO[keyDTO as keyof IConfigDTO] = objValue
			if (keyDTO === "webAbTest") {
				defaultSConfigDTO["webAbTest"].forEach((domainItem, domainIndex) => {
					domainItem.value.forEach((abTestItem, abTestIndex) => {
						const addoptedValue = EConfigFirebaseToDTO[abTestItem.param] as ABTestParam // as keyof IConfigDTO
						if (!!addoptedValue) {
							defaultSConfigDTO["webAbTest"][domainIndex].value[abTestIndex].param = addoptedValue
						} else {
							console.log(
	"ERROR: не найдено поле для А/Б теестирования. Либо поле указано не верно в RemoteConfig или либо в мепенге проекта!"
							)
						}
					})
				})
			}
		})

		logConig(defaultSConfigDTO)

		return defaultSConfigDTO
	}
}
*/

export class ConfigDTOService implements IConfigDTOService {
	public async fetchConfig() {
		logConig(mockedConfig)
		return mockedConfig as IConfigDTO
	}
}

export const configDTOService = new ConfigDTOService()

const logConig = (config: IConfigDTO) => {
	// TODO: log only in debug mode
	console.log("#############################################")
	console.log("GATEWAY: REMOTE CONFIG FETCHED")
	console.log(JSON.stringify(config, null, 1))
	console.log("#############################################")
}
