import { Trans, t } from "@lingui/macro"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import ArrowRight from "~/assets/ArrowRight.svg"
import BackIcon from "~/assets/Back.svg"
import LogoIcon from "~/assets/Logo.svg"
import { Bubble } from "~/components2/atoms/Bubble"
import { Flexed } from "~/components2/atoms/Flexed"
import { Header } from "~/components2/atoms/Header"
import { Input } from "~/components2/atoms/Input"
import { TextBody, TextHead } from "~/components2/atoms/Text"
import { Subscription } from "~/subscriptions-web/subscription/subscription"

export interface ProfileScreenProps {
	subscription: Subscription | null
	isStatusToProvideService: boolean
	isStatusToSuspendService: boolean
	isSubscriptionCancelled: boolean
	canSubscriptionBeCancelled: boolean

	isCanceling: boolean
	cancelSubscription: () => void
	cancelError: string
	email: string
}

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background-color: #f6f6f6;
	box-sizing: border-box;

	z-index: 50;

	overflow: hidden;
`

const Level = styled.div`
	position: absolute;
	bottom: 0;
	left: 0%;

	width: 100%;
	height: calc(100% - 64px);

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	padding-bottom: 44px;
	box-sizing: border-box;

	transition: left 0.4s;

	@media (orientation: landscape) {
		top: 100px;
	}
`

const Level1 = styled(Level)``

const Level2 = styled(Level)`
	left: 100%;
`

const Content = styled.div`
	position: relative;
	width: 100%;
	height: auto;

	padding: 0 20px;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	overflow-y: scroll;

	@media (orientation: landscape) {
		width: 480px;
	}

	&::-webkit-scrollbar {
		width: 0px;
	}

	&::-webkit-scrollbar-track {
		background: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: none;
		outline: none;
		border-radius: 50px;
	}
`

// TODO: вынести в отдельный компонент
const Bubbles = styled.div`
	width: 100%;
	height: auto;
	padding: 0;

	> div {
		width: 100%;
		height: 60px;
		padding: 10px 20px;
		margin: 0;
		box-sizing: border-box;

		box-shadow: inset 0px 1px 0px #ebebeb;

		> img {
			margin-top: 8px;
		}

		:first-child {
			box-shadow: none;
		}
	}
`

const Title = styled(TextBody)`
	width: 70%;
`

const TopBar = styled.div`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 64px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	padding: 0;
	box-sizing: border-box;

	@media (orientation: landscape) {
		top: 100px;
	}
`

const GoBack = styled.div`
	position: absolute;
	top: 12px;
	left: 20px;

	width: 40px;
	height: 40px;

	display: flex;
	justify-content: center;
	align-items: center;

	margin: 0;
	border-radius: 32px;
	background-color: rgba(88, 86, 214, 0.08);

	@media (orientation: landscape) {
		left: 80px;
		width: 64px;
		height: 64px;
	}
`

const Logo = styled.img`
	position: relative;
	width: 120px;
	height: auto;

	@media (orientation: landscape) {
		display: none;
	}
`

export const ProfileScreen = ({
	subscription,
	isStatusToProvideService,
	isStatusToSuspendService,
	isSubscriptionCancelled,
	canSubscriptionBeCancelled,

	cancelSubscription,
	isCanceling,
	cancelError,
	email,
}: ProfileScreenProps) => {
	const [currentLevel, changeLevel] = useState(1)

	const [newEmail, setNewEmail] = useState(email)

	const accountRef = useRef<HTMLDivElement>(null)
	const personalRef = useRef<HTMLDivElement>(null)

	const refs = [accountRef, personalRef]

	if (!subscription) {
		return (
			<>
				<Trans>NO SUBSCRIPTION AT THE MOMENT. SHOW PAYMENT SCEENS.</Trans>
			</>
		)
	}

	const startDate = subscription.periodStart //new Date(currSubscription!.current_period_start!*1000);
	const endDate = subscription.periodEnd //new Date(currSubscription!.current_period_end!*1000);

	// console.log("___SUBSCRIPTION___2___", subscription, startDate, endDate)
	// const CancelButton = () => {
	// 	return (
	// 		<button disabled={isCanceling} onClick={cancelSubscription}>
	// 			{isCanceling ? "Canceling..." : "Cancel subscription"}
	// 		</button>
	// 	)
	// }

	if (!isStatusToProvideService && !isStatusToSuspendService) {
		// this component is to show info when no subscription (look up),
		// or when subscription is in status to provide service to suspend service.
		return (
			<>
				<Trans>SOMETHING WENT WRONG.</Trans>
			</>
		)
	}
	// if (!isSubsciptionActive) {
	// 	return <>SOMETHING WENT WRONG.</>
	// }

	const goToLevel = (level: "prev" | "next") => {
		const newLevel = level === "prev" ? currentLevel - 1 : currentLevel + 1
		const currentRef = refs[currentLevel - 1]
		const nextRef = refs[newLevel - 1]

		changeLevel(newLevel)

		if (nextRef && nextRef.current && currentRef && currentRef.current) {
			nextRef.current.style.left = "0%"
			currentRef.current.style.left = level === "next" ? "-100%" : "100%"
		}
	}

	const onChangeEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const mail = evt.currentTarget.value

		setNewEmail(mail)
	}

	return (
		<Wrapper>
			<Header desktopSimplified simplified />
			<TopBar>
				{currentLevel > 1 && (
					<GoBack onClick={() => goToLevel("prev")}>
						<img alt="" src={BackIcon} />
					</GoBack>
				)}
				<Logo alt="" src={LogoIcon} />
			</TopBar>
			<Level1 ref={accountRef}>
				<Content>
					<TextHead centered size="small" margin="30px 0 0">
						<Trans>Manage Account</Trans>
					</TextHead>

					<TextBody size="small" color="#828691" margin="24px 0 8px">
						<Trans>Account</Trans>
					</TextBody>

					<Bubble padding="0">
						<Bubbles>
							<Flexed align="flex-start" onClick={() => goToLevel("next")}>
								<Title color="#000000" size="big">
									<Trans>Personal Info</Trans>
								</Title>
								<img alt="" src={ArrowRight} />
							</Flexed>
						</Bubbles>
					</Bubble>

					<TextBody size="small" color="#828691" margin="24px 0 8px">
						<Trans>Your Subscription</Trans>
					</TextBody>

					<Bubble padding="0" margin="0 0 14px">
						<Bubbles>
							<Flexed align="flex-start">
								<Title color="#000000" size="big">
									<Trans>Trial Period</Trans>
								</Title>
								<img alt="" src={ArrowRight} />
							</Flexed>
							<Flexed align="flex-start">
								<Title color="#000000" size="big">
									<Trans>Creation Date</Trans>
								</Title>
								<TextBody size="big" color="#828691">
									{getDateString(startDate) ?? null}
								</TextBody>
							</Flexed>
							<Flexed align="flex-start">
								<Title color="#000000" size="big">
									<Trans>Expiration Date</Trans>
								</Title>
								<TextBody size="big" color="#828691">
									{getDateString(endDate) ?? null}
								</TextBody>
							</Flexed>
							<Flexed align="flex-start">
								<Title color="#000000" size="big">
									<Trans>Payment Data</Trans>
								</Title>
								<img alt="" src={ArrowRight} />
							</Flexed>
							{subscription.status !== "canceled" && (
								<Flexed
									disabled={isCanceling}
									onClick={cancelSubscription}
									align="flex-start"
									// onClick={() => history.push("/cancel")}
								>
									<Title color="#828691" size="big">
										{/*Cancel Subscription*/}
										{isCanceling ? t`Canceling...` : t`Cancel subscription`}
									</Title>
								</Flexed>
							)}
						</Bubbles>
					</Bubble>
					<Title style={{ width: "100%", textAlign: "center" }}>
						{subscription.status === "canceled" && "Subscription was canceled"}
					</Title>
				</Content>
			</Level1>
			<Level2 ref={personalRef}>
				<Content>
					<TextHead centered size="small" margin="30px 0 0">
						<Trans>Personal Info</Trans>
					</TextHead>

					{/*return (*/}
					{/*    <div>*/}
					{/*        <br />*/}
					{/*        { startDate && `Current period start: ${startDate.toLocaleString()}`}<br />*/}
					{/*        { endDate && `Current period end: ${endDate.toLocaleString()}`}<br />*/}
					{/*        { subscription.status && `Status: ${subscription.status}`}<br />*/}
					{/*        { subscription.provider && `Billing provider: ${subscription.provider}`}<br />*/}

					{/*        { isSubscriptionCancelled && 'Subscription will be canceled at end of current period.'}<br />*/}
					{/*        { canSubscriptionBeCancelled && <CancelButton />*/}
					{/*        }*/}
					{/*        {cancelError}*/}
					{/*    </div>*/}
					{/*);*/}
					{/* <TextBody size="small" color="#828691" margin="24px 0 8px">
					 	<Trans>Name</Trans>
						</TextBody>
						<Input value={name} type="login" placeholder={t`Enter name here`} onChange={onChangeName} width="100%" />
					*/}

					<TextBody size="small" color="#828691" margin="24px 0 8px">
						<Trans>Email</Trans>
					</TextBody>
					<Input
						value={newEmail}
						type="email"
						placeholder="youremail@gmail.com"
						readonly
						onChange={onChangeEmail}
						width="100%"
					/>
				</Content>
			</Level2>
		</Wrapper>
		// <div>
		// 	<br />
		// 	{`Current period start: ${startDate.toLocaleString()}`}
		// 	<br />
		// 	{`Current period end: ${endDate.toLocaleString()}`}
		// 	<br />
		// 	{`Status: ${currSubscription?.status}`}
		// 	<br />
		// 	{`Price: ${currSubscription!.unit_amount! / 100}  ${currSubscription?.currency} for ${
		// 		currSubscription?.interval_count
		// 	} ${currSubscription?.interval}`}
		// 	<br />
		// 	{currSubscription?.cancel_at_period_end ? "Subscription will be canceled at end of current period." : ""}
		// 	<br />
		// 	{canSubscriptionBeCanceled && (
		// 		<button disabled={isCanceling} onClick={cancelSubscription}>
		// 			{isCanceling ? "Canceling..." : "Cancel subscription"}
		// 		</button>
		// 	)}
		// 	{cancelError}
		// </div>
	)
}

function getDateString(date: Date | undefined) {
	if (date) {
		return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}.${
			date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
		}.${date.getFullYear()}`
	}
}
