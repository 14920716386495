import crypto from "crypto"
import { amplitudeService } from "~/services/amplitude"
import { analytics } from "~/services/analytics"
import { EmailScreen } from "./email-screen"

export interface IEmailScreenContainerProps {
	label: string
	description: string
	onNext: (email: string) => void
}

export const EmailScreenContainer = (props: IEmailScreenContainerProps) => {
	const localOnNext = (email: string) => {
		amplitudeService.logEvent("[All] Onboarding | Email")
		analytics.track("sign_up")
		analytics.identify(
			"none",
			{
				sha256_email: email ? hashString(email.toLowerCase()) : "",
			},
			{
				plugins: {
					all: false,
					tiktokPixel: true,
				},
			}
		)
		props.onNext(email)
	}

	return <EmailScreen title={props.label} description={props.description} onNext={localOnNext} />
}

function hashString(str: string) {
	return crypto.createHash("sha256").update(str).digest("hex")
}
