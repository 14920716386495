/* MOCKED
import { Cache } from "@anion155/rixio-utils"
import Airtable from "airtable"
import memoize from "lodash.memoize"
import { getLocalizedFields } from "./airtable-utils"

export class AirtableService {
	private base: Airtable.Base

	constructor() {
		const client = new Airtable({
			apiKey: process.env.REACT_APP_AIRTABLE_KEY,
		})
		this.base = client.base(process.env.REACT_APP_AIRTABLE_BASE!)
	}

	tableFetcher = memoize(
		<T extends Airtable.FieldSet & { id: string }>(tableName: TableNames): AirtableFetcher<T, T> => {
			const table = this.base(tableName)
			return new AirtableFetcher(table as any, (d) => d.fields, tableName)
		}
	)
}

export class AirtableFetcher<T extends Airtable.FieldSet, U extends { id: string }> {
	private readonly recordsRaw: Promise<Airtable.Records<T>>
	readonly records: Promise<Airtable.Records<U>>

	constructor(table: Airtable.Table<T>, validateAndTransform: (data: Airtable.Record<T>) => U, tableName: TableNames) {
		this.recordsRaw = table.select({ pageSize: 20 }).all()
		this.records = this.recordsRaw.then((records) => {
			const result = records
				.map((r) => {
					const fields = getLocalizedFields(tableName, validateAndTransform(r)) as U

					return fields ? { ...r, fields } : undefined!
				})
				.filter((r) => !!r)

			return result
		}).then((r) => {

			console.log(r)
			return r
		})

	}
}
 */

import goalsData from "./goals.json"
import onboardingQuestionData from "./onboarding_question.json"
import onboardingTypeData from "./onboarding_type.json"

export type TableNames = "onboarding_type" | "onboarding_question" | "goals"

export class AirtableFetcher<T extends Airtable.FieldSet, U extends { id: string }> {
	readonly records: Promise<Airtable.Records<U>>

	constructor(tableName: TableNames) {
		let records = [] as Airtable.Records<U>
		if (tableName === "goals") {
			records = goalsData as unknown as Airtable.Records<U>
		} else if (tableName === "onboarding_question") {
			records = onboardingQuestionData as unknown as Airtable.Records<U>
		} else if (tableName === "onboarding_type") {
			records = onboardingTypeData as unknown as Airtable.Records<U>
		}

		this.records = Promise.resolve(records)
	}
}
export class AirtableService {
	tableFetcher = <T extends Airtable.FieldSet & { id: string }>(tableName: TableNames): AirtableFetcher<T, T> => {
		return new AirtableFetcher(tableName)
	}
}

export const airtableService = new AirtableService()
