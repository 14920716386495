import { IConfigDTO } from "./config-dto-types"

export const mockedConfig = {
	webLandingType: [
		{
			domain: "course",
			value: [
				{
					sortIndex: 0,
					value: "landing-delta",
				},
			],
		},
	],
	webOnboardingType: [
		{
			domain: "course",
			value: [
				{
					sortIndex: 0,
					value: "onboarding-juliet",
				},
			],
		},
	],
	webOnboardingTypeMan: [
		{
			domain: "course",
			value: [
				{
					sortIndex: 0,
					value: "onboarding-men",
				},
			],
		},
	],
	webPrepaywallType: [
		{
			domain: "course",
			value: [
				{
					sortIndex: 0,
					value: "prepaywall-alpha",
				},
			],
		},
	],
	webPaywallType: [
		{
			domain: "course",
			value: [
				{
					sortIndex: 0,
					//value: "paywall-juliett",
					value: "paywall-india",
				},
			],
		},
	],
	webSubscription: [
		{
			domain: "course",
			value: [
				{
					value: {
						paddleProductId: 33033,
						priceId: process.env.REACT_APP_STRIPE_PRICE_ID,
						planId: process.env.REACT_APP_PAYPAL_PLAN_ID,
						oldPrice: Number(process.env.REACT_APP_PRICE_OLD ?? 999),
						trial: {
							amount: Number(process.env.REACT_APP_TRIAL_PRICE ?? 99),
							currency: "usd",
							trialPeriodDays: Number(process.env.REACT_APP_TRIAL_PERIOD_DAYS ?? 1),
						},
						labels: {
							description:
								process.env.REACT_APP_LABELS_PRICE_DESCRIPTION ??
								"You'll have 1 day to see how Pora uses psychology to create lasting results",
							button: process.env.REACT_APP_LABELS_PRICE_BUTTON ?? "Start 1 Day Trial",
						},
						/*
						labels: {
							title: "Try 3 Trial Days with Pora Course",
							description: "You'll have 3 days to see how Pora uses psychology to create lasting results",
							button: "Start 3 Days Trial",
						},
 						*/
						locales: {
							ru: {
								description:
									"У вас будет 3 дня, чтобы увидеть, как Pora использует психологию для достижения долгосрочных результатов",
								button: "Начать 3-дневную пробную версию",
							},
						},
					},
					sortIndex: 0,
				},
			],
		},
	],
	webAbTest: [
		{
			domain: "course",
			value: [
				{
					active: "no",
					param: "webOnboardingType",
					variants: {
						A: {
							sortIndex: 0,
							value: "50%",
						},
						B: {
							sortIndex: 100,
							value: "50%",
						},
					},
				},
			],
		},
	],
	paywallFirstScreen: [
		{
			domain: "course",
			value: [
				{
					sortIndex: 0,
					value: "no",
				},
			],
		},
	],
} as IConfigDTO
