/* import { paypalSubscriptionGetCurrent } from "../paypal/paypal-subscription-get-current" */
import { stripeSubscriptionsGetCurrent } from "../stripe/get-current-subscription"
import { Subscription } from "./subscription"
//import { subscriptionFromPaypal } from "./subscription-converter.ts/subscription-from-paypal"
import { subscriptionFromStripe } from "./subscription-converter.ts/subscription-from-stripe"

export const subscriptionsGetCurrent = async (userId: string): Promise<Subscription[]> => {
	/*
	const subscriptionsPaypalPromise = paypalSubscriptionGetCurrent(userId).then((paypalSubscriptionList) => {
		const subscriptions: Subscription[] = []

		paypalSubscriptionList.forEach((paypalSubscriptionItem) => {
			const subscription = subscriptionFromPaypal(paypalSubscriptionItem)
			subscriptions.push(subscription)
		})

		return subscriptions
	})
 */
	const subscriptionsStripePromise = stripeSubscriptionsGetCurrent(userId).then((stripeSubscriptionList) => {
		const subscriptions: Subscription[] = []

		stripeSubscriptionList.forEach((stripeSubscriptionItem) => {
			const subscription = subscriptionFromStripe(stripeSubscriptionItem)
			subscriptions.push(subscription)
		})

		return subscriptions
	})

	//const result = await Promise.all([subscriptionsPaypalPromise, subscriptionsStripePromise])
	const result = await Promise.all([subscriptionsStripePromise])

	const subscriptions: Subscription[] = []

	result.forEach((subscriptionList) => {
		subscriptions.push(...subscriptionList)
	})

	return subscriptions
}
