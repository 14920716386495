import CircleAnimation from "@assets/lotties/LandingDeltaCircle.json"
import LinesAnimation from "@assets/lotties/LandingDeltaLines.json"
import TextAnimation from "@assets/lotties/LandingDeltaText.json"
import TextAnimationMan from "@assets/lotties/LandingDeltaTextMan.json"
import { Trans } from "@lingui/macro"
import { useEffect, useMemo, useRef, useState } from "react"
import Lottie, { Options as LottieOptions } from "react-lottie"
import { ActionButton, ActionButtonState } from "~/components/buttons"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { MobileButtonWrapper } from "~/views/onboarding/screens/quotes/quote-screen/quote-screen.styles"

import { Wrapper, Background, LandingWrapper } from "./delta-landing.styles"

const linesOptions: LottieOptions = {
	loop: false,
	autoplay: true,
	animationData: LinesAnimation,
	rendererSettings: {
		preserveAspectRatio: "none meet", // More info about the setting https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio
	},
}
const circleOptions: LottieOptions = {
	loop: false,
	autoplay: false,
	animationData: CircleAnimation,
	rendererSettings: {
		preserveAspectRatio: "none slice",
	},
}

const lottieStyle: React.CSSProperties = {
	position: "absolute",
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
}

export interface IDeltaLandingProps {
	onNext: () => void
	onboardingType: string | null
}

export const DeltaLanding = (props: IDeltaLandingProps) => {
	const size = useWindowLayout()
	const [isStoppedCircleAnimation, setIsStoppedCircleAnimation] = useState<boolean>(true)

	const textOptions = useMemo(
		() => ({
			loop: false,
			autoplay: true,
			animationData: !props.onboardingType ? TextAnimation : TextAnimationMan,
			rendererSettings: {
				preserveAspectRatio: "xMid yMid meet",
				clearCanvas: true,
				hideOnTransparent: true,
			},
		}),
		[props.onboardingType]
	)
	const buttonRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		setTimeout(() => {
			if (buttonRef.current) {
				buttonRef.current.style.opacity = "1"
				buttonRef.current.style.pointerEvents = "auto"
			}
		}, 500)
	}, [])

	return (
		<Wrapper>
			<MobileButtonWrapper z={100} ref={buttonRef}>
				<ActionButton
					size={size}
					state={ActionButtonState.Regular}
					onClick={() => {
						setIsStoppedCircleAnimation(false)
					}}
				>
					Skip
				</ActionButton>
			</MobileButtonWrapper>

			<Background />
			<LandingWrapper style={{ position: "relative" }}>
				<Lottie
					options={linesOptions}
					isPaused={false}
					isStopped={false}
					style={lottieStyle}
					isClickToPauseDisabled={true}
					eventListeners={[
						{
							eventName: "complete",
							callback: () => {
								setIsStoppedCircleAnimation(false)
							},
						},
					]}
					width="100%"
					height="100%"
				/>
				<Lottie
					options={textOptions}
					isStopped={false}
					isPaused={false}
					style={lottieStyle}
					isClickToPauseDisabled={true}
				/>
				<Lottie
					options={circleOptions}
					isStopped={isStoppedCircleAnimation}
					isPaused={false}
					style={lottieStyle}
					isClickToPauseDisabled={true}
					eventListeners={[
						{
							eventName: "complete",
							callback: () => props.onNext(),
						},
					]}
				/>
			</LandingWrapper>
		</Wrapper>
	)
}
