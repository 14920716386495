import Check from "@assets/Check.svg"
import { color, background } from "@components/shared/colors"
import { spacing } from "@components/shared/spacing"
import { typography } from "@components/shared/typography"
import styled, { css } from "styled-components"
import { IChoiceButtonProps } from "./choice-button"

export const Button = styled.button<IChoiceButtonProps>`
	position: relative;
	display: flex;
	flex-direction: column;

	text-align: ${({ isCentered }) => isCentered && "center"};

	outline: none;
	border: none;

	background: ${background.white};
	color: ${color.smoke};
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: ${spacing.borderRadius.small}px;

	cursor: pointer;

	margin: 8px 0;
	overflow: hidden;

	& span {
		min-width: 28px;
		min-height: 28px;
		box-sizing: border-box;
		border-radius: 50%;
		border: ${({ isChecked }) => (isChecked ? "none" : "2.5px solid #e7e8ea")};
		background: ${({ isChecked }) => (isChecked ? `url(${Check})` : "none")};
		overflow: hidden;
		padding: 0;

		${({ picture }) =>
			picture
				? css`
						position: absolute;
						top: 20px;
						right: 20px;

						border-color: ${color.white};
				  `
				: css`
						position: relative;
				  `};
	}

	> div {
		position: ${({ picture }) => (picture ? "absolute" : "relative")};
		bottom: 0;
		left: 0;
		box-sizing: border-box;
		width: 100%;
		height: ${({ size }) => (size === "Desktop" ? 64 : 56)}px;
		height: auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		background: #fff;

		padding: ${({ size }) =>
			size === "Desktop" ? `15px ${spacing.padding.horizontalBig}px` : `15px ${spacing.padding.horizontalSmall}px`};

		> p {
			width: 100%;
			text-align: ${({ isCentered }) => (isCentered ? "center" : "left")};
			margin: 0;
		}
	}

	${({ size, picture }) =>
		size === "Desktop"
			? css`
					font-family: ${typography.family.inter};
					font-style: ${typography.fontStyle.default};
					font-weight: ${typography.fontWeight.bold};
					font-size: ${typography.fontSize.b1}px;
					line-height: ${typography.lineSize.b1}px;

					letter-spacing: ${typography.letterSpacing.b}em;

					min-height: ${picture ? 216 : 64}px;
			  `
			: css`
					font-family: ${typography.family.inter};
					font-style: ${typography.fontStyle.default};
					font-weight: ${typography.fontWeight.bold};
					font-size: ${typography.fontSize.bm1}px;
					line-height: ${typography.lineSize.bm1}px;

					letter-spacing: ${typography.letterSpacing.b}em;

					min-height: ${picture ? 216 : 56}px;
			  `};

	${({ isStretch, size }) =>
		isStretch
			? css`
					width: 100%;
			  `
			: css`
					width: ${size === "Desktop" ? "480px" : "335px"};
			  `};

	transition: background 0.2s, color 0.2s;

	> img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 160px;
		min-width: 100%;
		max-height: 160px;
		object-fit: cover;
		margin: 0;
		top: 0;
		left: 0;
		min-width: 100%;
	}

	${({ isChecked, isDisabled }) =>
		isChecked
			? css`
					background: ${background.lotion};
					color: ${color.black};
			  `
			: css``};

	${({ isDisabled, size }) =>
		size === "Desktop"
			? css`
			:hover {
					background: ${!isDisabled && background.lotion};
					color: ${!isDisabled && color.black};
				}
			}
		`
			: css``};
`
