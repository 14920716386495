import { useElements, useStripe } from "@stripe/react-stripe-js"
import { PaymentRequestButtonElement } from "@stripe/react-stripe-js"
import { PaymentRequest, StripePaymentRequestButtonElementOptions } from "@stripe/stripe-js"
import { useState } from "react"
import { useAuthContext } from "../context"
import { ProgressStatus } from "../hooks/progress-status"
import { CardInfo, getPaymentMethodWithCard } from "./create-payment-method"
import {
	getCurrentSubscription,
	isStripeSubscriptionInActiveStatus,
	isStripeSubscriptionInTerminalStatus,
} from "./get-current-subscription"
import { handlePaymentThatRequiresCustomerAction } from "./handle-payment-that-requires-customer-action"
import { handleRequiresPaymentMethod } from "./handle-requires-payment-method"
import { onSubscriptionComplete } from "./on-subscription-complete"
import { retryInvoice } from "./retry-invoice"
import { stripeCreateSubscription } from "./stripe-create-subscription"

export interface PaymentAPIMethods {
	applePay: boolean
	googlePay: boolean
}

export interface PaymentAPI {
	paymentRequest: PaymentRequest
	paymentType: PaymentAPIMethods
}

export enum RESPONSE_CODE {
	UNEXPECTED_ERROR = 500,
	CARD_DATA_INCORRECT,
	CARD_NOT_ATTACHED_TO_CUSTOMER,
	CARD_DECLINED_AFTER_CONFIRM_ACTION,
	CARD_REQUERE_PAYMENT_METHODS,
}

export interface SubscribeAnonymousUserResponse {
	code:
		| RESPONSE_CODE.UNEXPECTED_ERROR
		| RESPONSE_CODE.CARD_DATA_INCORRECT
		| RESPONSE_CODE.CARD_NOT_ATTACHED_TO_CUSTOMER
		| RESPONSE_CODE.CARD_DECLINED_AFTER_CONFIRM_ACTION
		| RESPONSE_CODE.CARD_REQUERE_PAYMENT_METHODS

	message: string

	data?: {
		waitTime?: number
	}
}

export interface StripePaymentProgress {
	status: ProgressStatus
	errorResponse?: SubscribeAnonymousUserResponse
}

interface UserInfo {
	email?: string
	fbc?: string
	fbp?: string
	amplitudeDeviceId?: string
	amplitudeUserId?: string
}

interface SubscriptionInfo {
	priceId: string
	amount: number
	currency: string
	label?: string
}

export const useStripeSubscription = () => {
	const stripe = useStripe()
	const elements = useElements()
	const [paymentApi, setPaymentApi] = useState<PaymentAPI | null>(null)

	const { currentUser, setSubscriptionPaid } = useAuthContext()
	const [stripePaymentProgress, setStripePaymentProgress] = useState<StripePaymentProgress>({
		status: ProgressStatus.START,
	})

	// More info on payment request https://stripe.com/docs/stripe-js/elements/payment-request-button?html-or-react=react#react-collecting-shipping-info
	// Before use verify domain https://stripe.com/docs/stripe-js/elements/payment-request-button?html-or-react=react#verifying-your-domain-with-apple-pay
	const stripeCreatePaymentRequest = async ({
		subscriptionInfo,
		userInfo,
		paymentHandler,
	}: {
		subscriptionInfo: SubscriptionInfo
		userInfo: UserInfo
		paymentHandler?: (isSuccess: boolean, paymentType: PaymentAPIMethods, orderId: string) => void
	}): Promise<PaymentAPI | null> => {
		if (!stripe) {
			console.debug("STRIPE: APPLE PAY: stripe is null, payment request can not be created.")
			return null
		}

		const pr = stripe.paymentRequest({
			country: "CY",
			currency: subscriptionInfo.currency,
			total: {
				label: subscriptionInfo.label ?? "Pora subscription",
				amount: subscriptionInfo.amount,
			},
			requestPayerName: true,
			requestPayerEmail: true,
			requestShipping: false,
			requestPayerPhone: false,
		})

		// Check the availability of the Payment Request API.
		const result = await pr.canMakePayment()

		if (!result) {
			console.debug("STRIPE: APPLE PAY: NOT AVAILABLE.")
			setPaymentApi(null)
			return null
		}

		console.debug("STRIPE: APPLE PAY: AVAILABLE.")
		const payApi: PaymentAPI = {
			paymentRequest: pr,
			paymentType: {
				applePay: !!result?.applePay,
				googlePay: !!result?.googlePay,
			},
		}

		setPaymentApi(payApi)

		pr.on("paymentmethod", async (event) => {
			const paymentMethod = event.paymentMethod
			const [isSuccess, orderId] = await createStripeSubscription({
				subscriptionInfo,
				userInfo,
				paymentAPIPay: {
					paymentMethodId: paymentMethod.id,
				},
			})
			if (isSuccess) {
				event.complete("success")
			} else {
				event.complete("fail")
			}
			if (!!paymentHandler) {
				paymentHandler(isSuccess, payApi.paymentType, orderId)
			}
		})

		return payApi
	}

	// More info on styling https://stripe.com/docs/stripe-js/elements/payment-request-button?html-or-react=react#react-styling-the-element
	const StripePaymentRequestButton = () => {
		if (!paymentApi) {
			console.debug("STRIPE: APPLE PAY: BUTTON: NOT CREATED.")
			return null
		}
		console.debug("STRIPE: APPLE PAY: BUTTON: CREATED.")

		const options: StripePaymentRequestButtonElementOptions = {
			paymentRequest: paymentApi.paymentRequest,
			style: {
				paymentRequestButton: {
					type: "default",
					// One of 'default', 'book', 'buy', or 'donate'
					// Defaults to 'default'

					theme: "dark",
					// One of 'dark', 'light', or 'light-outline'
					// Defaults to 'dark'

					height: "64px",
					// Defaults to '40px'. The width is always '100%'.
				},
			},
		}

		return <PaymentRequestButtonElement options={options} />
	}

	// If you wish to design your own button instead of using the PaymentRequestButton(),
	// you may show your custom button based on the result of stripeCreatePaymentRequest().
	// Then, use this method to display the browser interface when your button is clicked.
	// Style guide for apple https://developer.apple.com/design/human-interface-guidelines/apple-pay/overview/introduction/
	// Style guide for google https://developers.google.com/pay/api/web/guides/brand-guidelines
	const stripeShowPaymentRequestDialog = () => {
		if (!!paymentApi) {
			paymentApi.paymentRequest.show()
		}
	}

	// When the subscribe-form is submitted we do a few things:
	//   1. Tokenize the payment method
	//   2. Create the subscription
	//   3. Handle any next actions like 3D Secure that are required for SCA.`
	const createStripeSubscription = async ({
		subscriptionInfo,
		userInfo,
		cardPay,
		paymentAPIPay,
	}: {
		subscriptionInfo: SubscriptionInfo
		userInfo: UserInfo
		cardPay?: CardInfo
		paymentAPIPay?: {
			paymentMethodId: string
		}
	}): Promise<[boolean, string]> => {
		try {
			setStripePaymentProgress({
				status: ProgressStatus.WORKING,
			})

			// 1. GET CURRENT SUBSCRIPTION STATUS
			const { currSubscription, currSubscriptionStatus } = await getCurrentSubscription(currentUser!.uid)
			if (isStripeSubscriptionInActiveStatus(currSubscriptionStatus)) {
				throw new Error("You have already subscribed.")
			}
			console.debug("STRIPE: There's no active subscription yet.")

			// 2. GET PAYMENT METHOD ID
			const paymentMethodId = paymentAPIPay
				? paymentAPIPay.paymentMethodId
				: await getPaymentMethodWithCard(stripe!, elements!, cardPay!)

			if (!currSubscription || isStripeSubscriptionInTerminalStatus(currSubscriptionStatus)) {
				console.debug("STRIPE: New subscription is to be created.")

				// 4a. THERE IS NO ACITVE, TRIALING OR NOT PAID SUBSCRIPTION - so we create one.
				// Throws an error if there's an error.
				const subscription = await stripeCreateSubscription({
					paymentMethodId,
					priceId: subscriptionInfo.priceId,
					email: userInfo.email,
					fbc: userInfo.fbc,
					fbp: userInfo.fbp,
					amplitudeDeviceId: userInfo.amplitudeDeviceId,
					amplitudeUserId: userInfo.amplitudeUserId,
				})
				console.debug("STRIPE: Subscription is created.", subscription)

				if (!isStripeSubscriptionInActiveStatus(subscription.status)) {
					console.debug("STRIPE: Subscription is not active yet.")
					const { paymentIntent } = await stripe!.retrievePaymentIntent(subscription.client_secret)
					// Some payment methods require a customer to be on session
					// to complete the payment process. Check the status of the
					// payment intent to handle these actions.
					await handlePaymentThatRequiresCustomerAction(stripe!, {
						paymentIntent,
						paymentMethodId,
						isRetry: true,
					})
					console.debug("STRIPE: Custom action is proccessed.")

					// If attaching this card to a Customer object succeeds,
					// but attempts to charge the customer fail, you
					// get a requires_payment_method error.

					//handleRequiresPaymentMethod({ paymentIntent, })
					console.debug("STRIPE: Require payment method is proccessed.")
				}

				// No more actions required. Provision your service for the user.
				onSubscriptionComplete(subscription)
				console.debug("STRIPE: Subscription is completed.")

				setStripePaymentProgress({
					status: ProgressStatus.DONE,
					errorResponse: undefined,
				})
				await setSubscriptionPaid!()
				return [true, subscription.id]
			} else {
				console.debug("STRIPE: Subscription exists and to be paid.")

				const invoice = await retryInvoice({
					invoiceId: currSubscription.latest_invoice_id,
					paymentMethodId,
					fbc: userInfo.fbc,
					fbp: userInfo.fbp,
				})

				// Some payment methods require a customer to be on session
				// to complete the payment process. Check the status of the
				// payment intent to handle these actions.
				await handlePaymentThatRequiresCustomerAction(stripe!, {
					paymentIntent: invoice.payment_intent,
					paymentMethodId,
					isRetry: true,
				})
				console.debug("STRIPE: Custom action is proccessed.")

				//onSubscriptionComplete();
				console.debug("STRIPE: Subscription is completed.")

				setStripePaymentProgress({
					status: ProgressStatus.DONE,
					errorResponse: undefined,
				})
				await setSubscriptionPaid!()
				return [true, currSubscription.id]
			}
		} catch (error) {
			console.error(error)
			setStripePaymentProgress({
				status: ProgressStatus.DONE,
				errorResponse: {
					code: RESPONSE_CODE.UNEXPECTED_ERROR,
					message: typeof error === "string" ? error : (error as any).message ?? error,
				},
			})
			return [false, ""]
		}
	}

	return {
		stripeCreatePaymentRequest,
		createStripeSubscription,
		stripePaymentProgress,
		stripeShowPaymentRequestDialog,
		StripePaymentRequestButton,
		stripePaymentAPI: paymentApi,
	}
}
