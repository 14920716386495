import { Loader } from "@components/loader/loader"
import { defineMessage, Trans } from "@lingui/macro"
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import IosIcon from "~/assets/BlackIos.svg"
import BluePlus from "~/assets/BluePlus.svg"
import FiveStars from "~/assets/FiveStars.png"
import MastercardPure from "~/assets/MastercardPure.svg"
import PaypalIcon from "~/assets/PayPal.svg"
import SSLLogo from "~/assets/SSLLogo.svg"
import StripeLogo from "~/assets/StripeLogo.svg"
import VisaPure from "~/assets/VisaPure.svg"
import { Block } from "~/components2/atoms/Block"
import { Button } from "~/components2/atoms/Button"
import { Flexed } from "~/components2/atoms/Flexed"
import { Input } from "~/components2/atoms/Input"
import { List } from "~/components2/atoms/List"
import { Colored, TextBody, TextWithIcon } from "~/components2/atoms/Text"

import { useQueryParams } from "~/hooks/use-query-params"
import { useWindowDimensions } from "~/hooks/use-window-dimensions"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { amplitudeService } from "~/services/amplitude"
import { DPaywallType } from "~/services/config/config-types"
import { i18nCustom } from "~/services/service/i18n-service"
import { PayPalButton } from "~/subscriptions-web/paypal/paypal-button"
import { SCROLL_TO_PAYMENT_FORM_SOURCES, usePaywall } from "../../use-paywall"
import { PrePaywall } from "../atoms/prepaywall"
import { PrePaywallTime } from "../atoms/prepaywall-time"
import { PriceAfterTrial } from "../atoms/price-after-trial"
import { PriceToday } from "../atoms/price-today"
import { TrialInformation } from "../atoms/trial-info"
import { PaywallZipInput } from "../paywall-zip-input"
import { Promocode } from "../promocode"

import {
	charlieBenefits,
	Content,
	ContentWrapper,
	CVCInput,
	ExpandedArea,
	ExpirationInput,
	MarketBubble,
	MarketsSimple,
	MediumBlock,
	PaymentBlock,
	PaymentBubble,
	PaymentDataInput,
	PaymentError,
	PaymentLabel,
	PaymentLogos,
	PaymentMethods,
	PaymentMultiInputWrapper,
	PaymentTitle,
	PayPalButtonContainer,
	PaywallFooter,
	PriceInfo,
	TimerBox,
	TryTitle,
	Wrapper,
} from "../styles"
import { PaywallProps } from "../types"
import RecommendsGlobe from "./assets/recommends.png"
import ReviewIcon from "./assets/review.png"
import { reviews, reviewsMan } from "./reviews"
import { EchoUserReviews, PaymentMethod, PayPalButtonWrapper } from "./styles"

export function IndiaPaywall({ onNext }: PaywallProps) {
	const layout = useWindowLayout()
	const timerRef = useRef<HTMLDivElement>(null)
	const timeRef = useRef<HTMLDivElement>(null)
	const addressZipRef = useRef<HTMLInputElement>(null)
	const onboardingType = useQueryParams().get("onboardingType")

	const windowSize = useWindowDimensions()

	const [focused, setFocused] = useState(false)
	const [method, setMethod] = useState<"paypal" | "card">("paypal")

	const [reviewsHidden, setReviewsHidden] = useState(true)
	const toggleReviews = useCallback(() => {
		setReviewsHidden((v) => !v)
	}, [])
	const shownReviews = useMemo(() => {
		const reviewsByOnboardingType = onboardingType === "man" ? reviewsMan : reviews

		return reviewsHidden ? reviewsByOnboardingType.slice(0, 3) : reviewsByOnboardingType
	}, [reviewsHidden, onboardingType])

	const {
		addressZip,
		product,
		paywallPrice,
		agreed,
		setAgreed,
		timeLeft,
		visibleTimer,
		submitProgress,
		errorToDisplay,
		stripeElementProps,
		paywallWeeklyPrice,
		createCardPaySubscription,
		createPayPalSubscription,
		handlePayPalApprove,
		handleScroll,
		logPaypalCancel,
		logPaypalEvent,
		logPaypalError,
		logScrollingToPaymentForm,
		logCheckoutEventOnce,
		webPrepaywallType,
	} = usePaywall(DPaywallType.India, addressZipRef, timeRef)

	useEffect(() => {
		if (timerRef.current) {
			timerRef.current.style.top = "12px"
		}
	}, [visibleTimer])

	useEffect(() => {
		if (agreed) {
			if (timeRef.current) {
				timeRef.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				})
			}
		}
	}, [agreed])

	const scrollToPayment = useCallback(
		(clickedElement: SCROLL_TO_PAYMENT_FORM_SOURCES) => () => {
			const el = document.getElementById("payment-choose")
			if (el) {
				el.scrollIntoView({ behavior: "smooth", block: "start" })
				logScrollingToPaymentForm(clickedElement)
			}
		},
		[logScrollingToPaymentForm]
	)

	defineMessage({ message: "Paying..." })
	defineMessage({ message: "View more" })
	defineMessage({ message: "Show less" })
	defineMessage({ message: "Day" })
	defineMessage({ message: "Days" })

	shownReviews.forEach((r) => {
		defineMessage({ message: r.name })
		defineMessage({ message: r.message })
	})

	console.log("___PRODUCTTT___", product)

	if (webPrepaywallType && product) {
		return (
			<Wrapper windowHeight={windowSize.height} id="scroll-wrapper">
				<ContentWrapper id="wrapper" onScroll={handleScroll}>
					<PrePaywall
						webPrepaywallType={webPrepaywallType}
						agreed={agreed}
						setAgreed={setAgreed}
						paywallPrice={paywallPrice!}
					/>
					<Content windowHeight={windowSize.height}>
						{agreed && (
							<ExpandedArea>
								<TimerBox ref={timerRef} onClick={scrollToPayment(SCROLL_TO_PAYMENT_FORM_SOURCES.TIME_BLOCK)}>
									<p>
										<Trans>Plan reserved for</Trans>{" "}
									</p>
									<span>
										{Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
									</span>
								</TimerBox>
								<PrePaywallTime
									windowHeight={windowSize.height}
									webPrepaywallType={webPrepaywallType}
									ref={timeRef}
									timeLeft={timeLeft}
									onClick={scrollToPayment(SCROLL_TO_PAYMENT_FORM_SOURCES.TIME_BLOCK)}
								/>
								<TryTitle layout={layout} size="big" margin="0 0 14px" centered>
									<Trans>Try</Trans> {product?.trial?.trialPeriodDays} <Trans>Trial</Trans>{" "}
									{(product?.trial?.trialPeriodDays ?? 0) > 1 ? i18nCustom._("Days") : i18nCustom._("Day")} <br />
									<Trans>with</Trans>{" "}
									<Colored color="">
										<Trans>Pora Course</Trans>
									</Colored>
								</TryTitle>

								<span id="benefits">
									<MediumBlock>
										<List align="left" items={charlieBenefits} />
									</MediumBlock>
								</span>

								<MediumBlock>
									<PriceToday
										product={product}
										paywallPrice={paywallPrice}
										onClick={scrollToPayment(SCROLL_TO_PAYMENT_FORM_SOURCES.PRICE_TODAY)}
									/>

									<PriceInfo>{product.labels.description}</PriceInfo>

									<PriceAfterTrial
										isSpecialOffer
										paywallWeeklyPrice={paywallWeeklyPrice}
										paywallPrice={paywallPrice}
										onClick={scrollToPayment(SCROLL_TO_PAYMENT_FORM_SOURCES.PRICE_AFTER_TRIAL)}
									/>

									<Promocode
										closed={({ openPromocode }) => (
											<TextWithIcon
												color="#5856D6"
												margin="24px 0 40px"
												icon={BluePlus}
												centered
												style={{ whiteSpace: "nowrap" }}
												onClick={openPromocode}
											>
												<Trans>Add a promocode</Trans>
											</TextWithIcon>
										)}
										opened={({ promocode, onChangePromocode, applyPromocode }) => (
											<Block margin="20px 0 8px">
												<TextBody size="big" color="rgba(130,134,145,0.5)">
													<Trans>Promocode</Trans>
												</TextBody>
												<Input
													placeholder="6-digits code"
													value={promocode}
													type="other"
													onChange={onChangePromocode}
													width="100%"
													maxLength={6}
												/>
												<Button
													disabled={promocode.length === 0}
													color="blue"
													title="Apply"
													width="100%"
													onClick={applyPromocode}
												/>
											</Block>
										)}
										applied={({ promocode }) => (
											<TextBody centered size="big" margin="20px 0 40px">
												<Trans>You've successfuly applied promocode</Trans>{" "}
												<Colored color="#5856d6">{promocode.toUpperCase()}</Colored>{" "}
												<Trans>and recieved one more month</Trans>{" "}
												<Colored color="#5856d6">
													<Trans>for free.</Trans>
												</Colored>{" "}
												<Trans>Thank you!</Trans>
											</TextBody>
										)}
									/>

									<EchoUserReviews>
										<div className="title">
											<Trans>User reviews</Trans>
										</div>
										<div className="reviews">
											{shownReviews.map((r) => (
												<div key={r.name} className="review">
													<div className="row">
														<img className="avatar" src={r.avatar} alt={r.name} />
														<div className="col">
															<div className="name">
																{i18nCustom._(r.name)}
																<img src={ReviewIcon} alt="" />
															</div>
															<div className="recommends">
																<img src={RecommendsGlobe} alt="" />
																<span>
																	<Trans>recommends</Trans>{" "}
																	<span className="power">
																		<Trans>Pora</Trans>
																	</span>
																</span>
															</div>
														</div>
													</div>
													<div className="message">{i18nCustom._(r.message)}</div>
												</div>
											))}
										</div>
										<button className="view-more" onClick={toggleReviews}>
											{reviewsHidden ? i18nCustom._("View more") : i18nCustom._("Show less")}
										</button>
									</EchoUserReviews>

									<PaymentMethods id="payment-choose">
										<PaymentMethod active>
											<img alt="" src={VisaPure} />
											<img alt="" src={MastercardPure} />
										</PaymentMethod>
										<PayPalButtonWrapper>
											<img alt="" src={PaypalIcon} />
											<PayPalButtonContainer>
												<PayPalButton
													options={{
														vault: true,
														intent: "subscription",
														clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
													}}
													style={{ color: "white", size: "responsive" }}
													createSubscription={createPayPalSubscription}
													onApprove={handlePayPalApprove}
													onClick={logPaypalEvent}
													onError={logPaypalError}
													catchError={logPaypalError}
													onCancel={logPaypalCancel}
												/>
											</PayPalButtonContainer>
										</PayPalButtonWrapper>
									</PaymentMethods>

									{/* STRIPE FORM: */}

									<form id="payment-form" onSubmit={createCardPaySubscription}>
										<PaymentBubble>
											<PaymentTitle>
												<Trans>Payment Info</Trans>
											</PaymentTitle>
											<PaymentLogos paywallType="bravo">
												<img alt="" src={StripeLogo} />
												<img alt="" src={SSLLogo} />
											</PaymentLogos>
											<PaymentBlock fullwidth>
												<PaymentLabel>
													<Trans>Card</Trans>
												</PaymentLabel>
												<PaymentDataInput>
													<CardNumberElement
														{...stripeElementProps}
														onFocus={() => {
															if (errorToDisplay) {
																setFocused(true)
															}
															amplitudeService.logEvent("[All] Paywall | Card number")
														}}
														onBlur={() => setFocused(false)}
													/>
												</PaymentDataInput>
											</PaymentBlock>
											<PaymentBlock fullwidth>
												<PaymentLabel>
													<Trans>Expires on</Trans>
												</PaymentLabel>
												<PaymentMultiInputWrapper>
													<ExpirationInput>
														<CardExpiryElement
															{...stripeElementProps}
															onFocus={() => {
																if (errorToDisplay) {
																	setFocused(true)
																}
																amplitudeService.logEvent("[All] Paywall | Expires date")
															}}
															onBlur={() => setFocused(false)}
														/>
													</ExpirationInput>
													<CVCInput>
														<CardCvcElement
															{...stripeElementProps}
															onFocus={() => {
																if (errorToDisplay) {
																	setFocused(true)
																}
																amplitudeService.logEvent("[All] Paywall | CVC")
															}}
															onBlur={() => setFocused(false)}
														/>
													</CVCInput>
													<PaywallZipInput
														ref={addressZipRef}
														addressZip={addressZip}
														onFocus={() => {
															if (errorToDisplay) {
																setFocused(true)
															}
														}}
														onBlur={() => setFocused(false)}
													/>
												</PaymentMultiInputWrapper>
											</PaymentBlock>
										</PaymentBubble>

										<Button
											type="submit"
											color="blue"
											margin="24px 0 24px"
											title={submitProgress ? i18nCustom._("Paying...") : product.labels.button}
											width="100%"
											onClick={logCheckoutEventOnce}
										/>

										{errorToDisplay && errorToDisplay.length && !focused && (
											<PaymentError>{errorToDisplay}</PaymentError>
										)}
									</form>

									<MarketsSimple>
										<div>
											<span>
												<img alt="" src={IosIcon} />
												<p>
													<Trans>App Store</Trans>
												</p>
											</span>
										</div>
										<div>
											<MarketBubble>
												<img alt="" src={FiveStars} />
												<p>
													4.8<span>/5</span>
												</p>
											</MarketBubble>
										</div>
										<div>
											<MarketBubble>
												<p>
													1M+{" "}
													<span>
														<Trans>downloads</Trans>
													</span>
												</p>
											</MarketBubble>
											<MarketBubble>
												<p>
													12K{" "}
													<span>
														<Trans>5-star ratings</Trans>
													</span>
												</p>
											</MarketBubble>
										</div>
									</MarketsSimple>

									<TrialInformation
										paywallType={DPaywallType.India}
										trialPeriodDays={product.trial.trialPeriodDays}
										paywallPrice={paywallPrice}
									/>

									{/* {method === "card" ? ( */}
									<Flexed direction="row" justify="center">
										<PaymentLogos paywallType="bravo">
											<img alt="" src={StripeLogo} />
											<img alt="" src={SSLLogo} />
										</PaymentLogos>
									</Flexed>
									{/* ) : null} */}
								</MediumBlock>

								<PaywallFooter>
									<p>
										{/* Copyright ® 2019-2022 Pora. */}
										<Trans>Copyright</Trans> ® 2022 Pora.
										<br />
										<Trans>All Rights Reserved</Trans>
									</p>
								</PaywallFooter>
							</ExpandedArea>
						)}
					</Content>
				</ContentWrapper>
			</Wrapper>
		)
	} else return <Loader />
}
