import crypto from "crypto"
import Analytics from "analytics"
import { name } from "root/package.json"
import { userAgent } from "~/utils/platform"
import { DSubscriptionValue } from "../config/config-types"
import { facebookPixelPlugin } from "./facebook-pixel"
import { gtagPlugin } from "./gtag"
import { tiktokPixelPlugin } from "./tiktok-pixel"
import { pluginTrackWrap, TypedAnalytics } from "./wrapper"

export type AnalyticsEventsMap = {
	/* eslint-disable camelcase */
	sign_up: void
	add_to_cart: DSubscriptionValue
	purchase: { product: DSubscriptionValue; orderID: string }
	checkout: DSubscriptionValue
	/* eslint-enable camelcase */
}

function parseProduct(product: DSubscriptionValue): ParsedProduct {
	return {
		currency: product && product.trial ? product.trial.currency.toUpperCase() : "",
		value: product && product.trial ? product.trial.amount / 100 : 0,
	}
}

const PORA_PRODUCT = "pora_premium"
function tiktokPrepareProduct(product: DSubscriptionValue) {
	const parsedProduct = parseProduct(product)
	return {
		content_id: PORA_PRODUCT,
		content_type: "product",
		content_name: PORA_PRODUCT,
		quantity: 1,
		value: parsedProduct.value,
		price: parsedProduct.value,
		currency: parsedProduct.currency,
	}
}

type ParsedProduct = { currency: string; value: number }
type FacebookEventsMap = {
	AddToCart: ParsedProduct
	CompleteRegistration: void
	StartCheckout: ParsedProduct
	Purchase: ParsedProduct
}

type TiktokProduct = {
	// eslint-disable-next-line camelcase
	content_id: string
	// eslint-disable-next-line camelcase
	content_type: string
	// eslint-disable-next-line camelcase
	content_name: string
	quantity: number
	value: number
	price: number
	currency: string
}
type TiktokEventsMap = {
	AddToCart: TiktokProduct
	InitiateCheckout: TiktokProduct
	CompletePayment: TiktokProduct
	// eslint-disable-next-line camelcase
	CompleteRegistration: { content_id: string }
}
export const analytics = Analytics({
	app: name,
	plugins: [
		gtagPlugin({
			measurementId: process.env.REACT_APP_GOOGLE_CONTAINER_ID!,
		}),
		pluginTrackWrap<AnalyticsEventsMap, TiktokEventsMap>()(
			tiktokPixelPlugin({ pixelId: process.env.REACT_APP_TIKTOK_PIXEL_ID! }),
			{
				sign_up: () => ["CompleteRegistration", { content_id: PORA_PRODUCT }],
				checkout: (product) => ["InitiateCheckout", tiktokPrepareProduct(product)],
				purchase: ({ product }) => [
					"CompletePayment",
					{
						...tiktokPrepareProduct(product),
						// eslint-disable-next-line no-nested-ternary
						value: userAgent.Android || userAgent.iOS ? 10 : tiktokPrepareProduct(product).value,
					},
				],
			}
		),
		pluginTrackWrap<AnalyticsEventsMap, FacebookEventsMap>()(
			facebookPixelPlugin({ pixelId: process.env.REACT_APP_FACEBOOK_PIXEL! }),
			{
				sign_up: "CompleteRegistration",
				checkout: (product) => ["StartCheckout", parseProduct(product)],
				purchase: ({ product }) => [
					"Purchase",
					{
						...parseProduct(product),
						// eslint-disable-next-line no-nested-ternary
						value: userAgent.Android || userAgent.iOS ? 10 : parseProduct(product).value,
					},
				],
			}
		),
	],
}) as TypedAnalytics<AnalyticsEventsMap>
