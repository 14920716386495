import { ChoiceButton } from "@components/buttons"
import { ActionButton } from "@components/buttons"
import { Trans } from "@lingui/macro"
import { forwardRef, PropsWithChildren, useState } from "react"
import { useWindowLayout } from "~/hooks/use-window-layout"

import { Wrapper, Question, Options, Shadow } from "./checklist-picture-screen.styles"

export interface IChecklistPictureScreenProps {
	label: string
	options: string[]
	attachments: string[]
	onNext: (s: any) => void
}

export const ChecklistPictureScreen = forwardRef<
	unknown,
	PropsWithChildren<IChecklistPictureScreenProps & JSX.IntrinsicElements["div"]>
>(({ ...rest }, ref: any) => {
	const size = useWindowLayout()

	const [checked, setChecked] = useState([] as number[])

	const onCheck = (index: number) => {
		const alreadyChecked = checked.indexOf(index)

		if (alreadyChecked === -1) {
			setChecked([...checked, index])
		} else {
			setChecked([...checked].filter((_, index) => index !== alreadyChecked))
		}
	}

	const onClickNext = () => {
		const value = checked.map((check) => rest.options[check])
		rest.onNext({ value })
	}

	return (
		<Wrapper ref={ref} size={size}>
			<Question size={size}>{rest.label}</Question>
			<Options>
				{rest.options.map((option, index) => (
					<ChoiceButton
						key={option}
						size={size}
						isCheckable
						isChecked={checked.indexOf(index) !== -1}
						onClick={() => onCheck(index)}
						picture={rest.attachments[index]}
					>
						{option}
					</ChoiceButton>
				))}
				<Shadow>
					{checked.length > 0 && (
						<ActionButton onClick={onClickNext} size={size}>
							<Trans>Continue</Trans>
						</ActionButton>
					)}
				</Shadow>
			</Options>
		</Wrapper>
	)
})
