import { Loader } from "@components/loader/loader"
import { useConfig } from "~/contexts/config-context"
import { DPaywallType } from "~/services/config/config-types"
import { AlphaPaywall } from "~/views/paywalls/screens/alpha-paywall"
import { BravoPaywall } from "~/views/paywalls/screens/bravo-paywall"
import { CharliePaywall } from "~/views/paywalls/screens/charlie-paywall"
import { DeltaPaywall } from "~/views/paywalls/screens/delta-paywall"
import { EchoPaywall } from "~/views/paywalls/screens/echo-paywall/echo-paywall"
import { IndiaPaywall } from "~/views/paywalls/screens/india-paywall/india-paywall"
import { JuliettPaywall } from "~/views/paywalls/screens/juliett-paywall/juliett-paywall"

export interface Props {
	onNext?: (params?: any) => void
	onPrev?: () => void
}

export const OnboardingPaywallScreen = ({ onNext }: Props): JSX.Element => {
	const remoteConfig = useConfig()

	switch (remoteConfig?.webPaywallType) {
		case DPaywallType.Alpha:
			return <AlphaPaywall onNext={onNext} />
		case DPaywallType.Bravo:
			return <BravoPaywall onNext={onNext} />
		case DPaywallType.Charlie:
			return <CharliePaywall onNext={onNext} />
		case DPaywallType.Delta:
			return <DeltaPaywall onNext={onNext} />
		case DPaywallType.Echo:
			return <EchoPaywall onNext={onNext} />
		case DPaywallType.India:
			return <IndiaPaywall onNext={onNext} />
		case DPaywallType.Juliett:
			return <JuliettPaywall onNext={onNext} />
		default:
			return <Loader />
	}
}
