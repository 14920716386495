import { Trans } from "@lingui/macro"
import { PaywallFooter } from "../styles"

// const currentYear = new Date().getFullYear()

export function Copyright() {
	return (
		<PaywallFooter>
			<p>
				{/* Copyright ® 2021-2023 Pora. */}
				<Trans>Copyright ® 2021-2023 Pora.</Trans>
				<br />
				<Trans>All Rights Reserved</Trans>
			</p>
		</PaywallFooter>
	)
}
