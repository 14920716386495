import { useEffect, useState } from "react"
import { useAuthContext } from "../context/auth-context"
import { callFirebaseFunction } from "../server-api/call-firebase-function"

export const useUpgradeAnonymousUser = (onSuccess?: (email: string) => void) => {
	const [state, setState] = useState(false)
	const { isUserEmailUpdating, userEmailUpdatingError, upgradeAnonymousUser, currentUser } = useAuthContext()

	useEffect(() => {
		console.log("IS USER EMAIL UPDATING", isUserEmailUpdating)
		if (isUserEmailUpdating) {
			setState(true)
			// localStorage.setItem('state', 'true')
		}
	}, [isUserEmailUpdating])

	useEffect(() => {
		console.log("SUCCESS BEFORE BEFORE", !isUserEmailUpdating, !userEmailUpdatingError, state)
		if (!isUserEmailUpdating && !userEmailUpdatingError && state) {
			console.log("SUCCESS BEFORE")
			if (onSuccess) {
				console.log("SUCCESS", currentUser?.email)
				onSuccess(currentUser?.email ?? "")
				console.log("SUCCESS AFTER", currentUser?.email)
			}
		}
	}, [state, onSuccess, isUserEmailUpdating, userEmailUpdatingError, currentUser])

	const upgradeAnonymousUser2 = async (email: string) => {
		await callFirebaseFunction("updateEmail", { email, uid: currentUser?.uid })
		await upgradeAnonymousUser!(email)
	}

	return {
		upgradeAnonymousUser: upgradeAnonymousUser2,
		isUserEmailUpdating,
		userEmailUpdatingError,
	}
}
