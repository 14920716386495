/*
import { firebaseFunctions } from "~/services/firebase/firebase-app"

export const callFirebaseFunction = async <T, R>(functionName: string, params: T): Promise<R> => {
	const firebaseRequest = firebaseFunctions.httpsCallable(functionName)
	const response = await firebaseRequest(params)

	const data = response.data as R

	return data
}
*/

import { UserPreferences } from "../context/preferences-context/user-preferences"
import { StripeCreateSubscriptionForAnonymousUserParams } from "."

//
const MOCK: Record<string, (params: any) => Promise<unknown>> = {
	stripeSubscriptionPricesListV3: () =>
		Promise.resolve({
			data: [
				{
					priceId: process.env.REACT_APP_STRIPE_PRICE_ID,
					active: true,
					amount: Number.parseInt(process.env.REACT_APP_PRICE_AFTER_TRIAL ?? "5999", 10),
					currency: "usd",
					interval: process.env.REACT_APP_PRICE_INTERVAL ?? "month",
					intervalCount: Number.parseInt(process.env.REACT_APP_PRICE_INTERVAL_COUNT ?? "2", 10),
				},
			],
		}),
	stripeSubscriptionPayV3: async ({
		paymentMethodId,
		priceId,
		email,
	}: /* , amplitudeUserId */
	StripeCreateSubscriptionForAnonymousUserParams) => {
		//const query = new URLSearchParams({payment_method_id: params.paymentMethodId})
		const url = new URL(`${process.env.REACT_APP_API_PREFIX}/stripe/create-payment-intent`)
		url.searchParams.set("payment_method_id", paymentMethodId)
		const body = { payment_method_id: paymentMethodId, price_id: priceId, email }
		const resp = await fetch(String(url), {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			body: JSON.stringify(body),
		})
		if (!resp.ok) {
			let message = "Unknown server error. Please try again latter."
			const json = await resp.json()
			if ("message" in json) {
				message = json["message"]
			}
			throw new Error(message)
		}

		const { clientSecret } = await resp.json()
		return { subscription: { status: "", client_secret: clientSecret } }
	},
	savePreferences: async ({ answers = new Map(), uid: userID }: UserPreferences & { uid: string }) => {
		const url = new URL(`${process.env.REACT_APP_API_PREFIX}/interview/save`)
		const preparedAnswers = []
		for (const [questionId, values] of answers.entries()) {
			preparedAnswers.push({ question_id: questionId === "email_ready" ? "email" : questionId, values })
		}

		const body = {
			request: { userID },
			test_id: "glowbe",
			answers: preparedAnswers,
		}

		const resp = await fetch(String(url), {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
	},
	updateEmail: async ({ email, uid: userID }: { email: string; uid: string }) => {
		const url = new URL(`${process.env.REACT_APP_API_PREFIX}/interview/save`)

		const body = {
			request: { userID },
			test_id: "glowbe",
			answers: [{ question_id: "email", values: [email] }],
		}

		const resp = await fetch(String(url), {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
	},
}

export const callFirebaseFunction = async <T, R>(functionName: string, params: T): Promise<R> => {
	console.log("*** FirebaseFunction", functionName, params)
	let response: Promise<unknown> = Promise.resolve({})
	if (functionName in MOCK) {
		response = MOCK[functionName](params)
	}
	return response as unknown as R
}
