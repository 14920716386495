import { ChoiceButton } from "@components/buttons"
import { forwardRef, PropsWithChildren, useCallback } from "react"
import { useWindowLayout } from "~/hooks/use-window-layout"

import { Wrapper, Label } from "./select-screen.styles"

export interface ISelectScreenProps {
	label: string
	options: string[]
	onNext: (props: any) => void
}

export const SelectScreen = forwardRef<unknown, PropsWithChildren<ISelectScreenProps & JSX.IntrinsicElements["div"]>>(
	({ label, onNext, options }, ref: any) => {
		const size = useWindowLayout()
		const handleClick = useCallback(
			(option: any) => {
				return () => {
					onNext({ value: [option] })
				}
			},
			[onNext]
		)

		return (
			<Wrapper ref={ref} size={size}>
				<Label size={size}>{label}</Label>
				{options.map((option, index) => (
					<ChoiceButton key={option} size={size} onClick={handleClick(option)}>
						{option}
					</ChoiceButton>
				))}
			</Wrapper>
		)
	}
)
