import LandingArrow from "@assets/LandingArrow.svg"
import OrangeLogo from "@assets/OrangeLogo.svg"

import { ActionButton, ActionButtonAppearance } from "@components/buttons"
import { Trans } from "@lingui/macro"
import { useWindowLayout } from "~/hooks/use-window-layout"

import {
	Wrapper,
	Background,
	LandingWrapper,
	Logo,
	LandingBody,
	TextWithImage,
	ButtonsWrapper,
	Copyright,
} from "./alpha-landing.styles"

export interface IAlphaLandingProps {
	onClickMaleCourse: () => void
	onClickFemaleCourse: () => void
}

export const AlphaLanding = (props: IAlphaLandingProps) => {
	const size = useWindowLayout()

	return (
		<Wrapper size={size}>
			<Background />
			<LandingWrapper size={size}>
				<Logo>
					<img alt="" src={OrangeLogo} />
				</Logo>
				<LandingBody size={size}>
					<p>
						<Trans>Just 10 min a day to get visible results with Pora</Trans>
					</p>
					<TextWithImage style={{ marginBottom: 0 }}>
						<Trans>What gender are you?</Trans>
						<img alt="" src={LandingArrow} />
					</TextWithImage>
					<ButtonsWrapper>
						<ActionButton size={size} appearance={ActionButtonAppearance.Pink} onClick={props.onClickMaleCourse}>
							<Trans>Male</Trans>
						</ActionButton>
						<ActionButton size={size} appearance={ActionButtonAppearance.Pink} onClick={props.onClickFemaleCourse}>
							<Trans>Female</Trans>
						</ActionButton>
					</ButtonsWrapper>
				</LandingBody>
			</LandingWrapper>
			<Copyright>
				{/* Copyright ® 2021-2023 Pora.{size === "Mobile" && <br />}
				All Rights Reserved */}
				<Trans>Copyright ® 2021-2023 Pora.</Trans>
				{size === "Mobile" && <br />}
				<Trans>All Rights Reserved</Trans>
			</Copyright>
		</Wrapper>
	)
}
