import { Atom } from "@rixio/atom"
import React, { useReducer, useContext, useEffect } from "react"
// eslint-disable-next-line
import firebaseNameSpace from "firebase/app"
// import { firebaseApp } from "~/services/firebase/firebase-app"
import { firebaseApp } from "~/services/firebase/firebase-app"
import * as server from "../server-api"
import { RESPONSE_CODE } from "../server-api/response-codes"

export const currentUser$ = Atom.create<firebaseNameSpace.User | null>(null)

export interface DataState {
	// When app starts, firebase app is loading and isFirebaseLoaded = false
	isFirebaseLoaded: boolean

	isUserEmailUpdating: boolean
	userEmailUpdatingError: server.UpdateAnonymousUserEmailResponse | null

	currentUser: firebaseNameSpace.User | null

	// User statuses
	isUserSignedIn: boolean
	// When user signed in, it can be in 3 states:
	// 1. Anonymous (without email)
	isUserAnonymous: boolean
	// 2. With either verified or not verified email.
	isUserVerified: boolean

	// Subscription plans
	// (here can add more subscription plans)
	isPro: boolean

	// Billing intergration accounts
	isWeb: boolean
}

interface DataActions {
	upgradeAnonymousUser: (email: string) => Promise<void>
	setSubscriptionPaid: () => Promise<void>
}

const initialState: DataState = {
	isFirebaseLoaded: false,

	isUserEmailUpdating: false,
	userEmailUpdatingError: null,

	currentUser: null,

	isUserSignedIn: false,
	isUserAnonymous: false,
	isUserVerified: false,

	isPro: false,
	isWeb: false,
}

export type DataContext = DataState & Partial<DataActions>

enum DATA_ACTION {
	SIGNED_IN,
	SIGNED_OUT,
	UPGRADE_ANONYMOUS_USER_PENDING,
	UPGRADE_ANONYMOUS_USER_FINISHED,
}

type DispatchAction =
	| {
			type: DATA_ACTION.SIGNED_IN
			payload: {
				user: firebaseNameSpace.User
				isPro: boolean
				isWeb: boolean
			}
	  }
	| {
			type: DATA_ACTION.SIGNED_OUT
	  }
	| {
			type: DATA_ACTION.UPGRADE_ANONYMOUS_USER_PENDING
	  }
	| {
			type: DATA_ACTION.UPGRADE_ANONYMOUS_USER_FINISHED
			payload: {
				user: firebaseNameSpace.User
				error: server.UpdateAnonymousUserEmailResponse | null
			}
	  }

type Reducer = (prevState: DataState, action: DispatchAction) => DataState

const reducer: Reducer = (prevState, action) => {
	switch (action.type) {
		case DATA_ACTION.SIGNED_IN:
			return {
				...prevState,
				isFirebaseLoaded: true,

				isUserEmailUpdating: false,

				currentUser: action.payload.user,
				isPro: action.payload.isPro,
				isWeb: action.payload.isWeb,

				isUserSignedIn: true,
				isUserAnonymous: action.payload.user.isAnonymous,
				isUserVerified: action.payload.user.emailVerified,
			}
		case DATA_ACTION.SIGNED_OUT:
			return {
				...initialState,
				isFirebaseLoaded: true,
			}
		case DATA_ACTION.UPGRADE_ANONYMOUS_USER_PENDING:
			return {
				...prevState,
				isUserEmailUpdating: true,
			}
		case DATA_ACTION.UPGRADE_ANONYMOUS_USER_FINISHED:
			return {
				...prevState,

				isUserEmailUpdating: false,
				userEmailUpdatingError: action.payload.error,

				currentUser: action.payload.user,

				isUserSignedIn: true,
				isUserAnonymous: action.payload.user.isAnonymous,
				isUserVerified: action.payload.user.emailVerified,
			}
		default:
			return prevState
	}
}

const idToken = {
	claims: {
		pro: false,
		web: false,
	},
}

/* FIXME MOCK get user_id from backend */

/*
			fetchUser().then((uid) => {
				const user = createUser(uid)
				currentUser$.set(user)
				if (user) {
					const isPro = !!idToken.claims.pro
					const isWeb = !!idToken.claims.web
					dispatch({
						type: DATA_ACTION.SIGNED_IN,
						payload: {
							user,
							isPro,
							isWeb,
						},
					})
				}
			})

*/

const createAuthContext = (reducer: Reducer, initialState: DataState) => {
	const AuthContext = React.createContext<DataContext>(initialState)

	const AuthProvider = ({ children }: any) => {
		const [state, dispatch] = useReducer(reducer, initialState)
		useEffect(() => {
			firebaseApp
				.auth()
				.signInAnonymously()
				.then((_user) => {
					const user = _user as unknown as firebaseNameSpace.User

					if (user) {
						currentUser$.set(user)

						const isPro = !!idToken.claims.pro
						const isWeb = !!idToken.claims.web
						dispatch({
							type: DATA_ACTION.SIGNED_IN,
							payload: {
								user,
								isPro,
								isWeb,
							},
						})
					}
				})
		}, [])

		//Uncommetn and specify user localization (create useLocalization())
		//firebase.auth().languageCode = 'fr';.

		//Uncomment if required
		//SESSION - auth state is cleared when tab is closed, so the user is authenticated only on one tab!
		//NONE - auth state is cleared when page is refreshed
		//LOCAL - auth state is not cleared even when the browser is closed (default)
		//firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
		/* MOCKED
		useEffect(
			() => {
				const unsubscribe1 = firebaseApp.auth().onIdTokenChanged(async (user) => {
					currentUser$.set(user)
					if (user) {
						const onlineToken = () => {
							const metadataRef = firebaseApp.firestore().collection("USER_METADATA").doc(`${user.uid}`)
							const callback = async (snapshot: firebaseNameSpace.firestore.DocumentSnapshot) => {
								// Force refresh to pick up the latest custom claims changes.
								const idToken = await user.getIdTokenResult(true)
								const isPro = !!idToken.claims.pro
								const isWeb = !!idToken.claims.web
								dispatch({
									type: DATA_ACTION.SIGNED_IN,
									payload: {
										user,
										isPro,
										isWeb,
									},
								})
							}
							metadataRef.onSnapshot(callback)
						}

						// const onSignInToken = async () => {
						// 	const idToken = await user.getIdTokenResult(true)
						// 	const isPro = !!idToken.claims.pro
						// 	const isWeb = !!idToken.claims.web
						// 	if (isWeb) {
						// 		onlineToken()
						// 	} else {
						// 		dispatch({
						// 			type: DATA_ACTION.SIGNED_IN,
						// 			payload: {
						// 				user,
						// 				isPro,
						// 				isWeb,
						// 			},
						// 		})
						// 	}
						// }

						//await onSignInToken()
						//TODO: Read from config
						// const isOnlineTokenChange = true
						// if (isOnlineTokenChange) {
						onlineToken()
						// } else {
						// 	await onSignInToken()
						// }
					} else {
						dispatch({ type: DATA_ACTION.SIGNED_OUT })
					}
				})
				// const unsubscribe2 = firebaseApp.auth().onIdTokenChanged(async (user)=>{

				// })
				return () => {
					unsubscribe1()
					//unsubscribe2()
				}
			},
			[] //TODO: Do we need to add dispatch here ????
		)
		*/

		const upgradeAnonymousUser = async (email: string) => {
			try {
				dispatch({ type: DATA_ACTION.UPGRADE_ANONYMOUS_USER_PENDING })

				console.log("RESPONSE", email)
				const response = await server.updateAnonymousUserEmail(email)
				console.log("RESPONSE AFTER", response)

				dispatch({
					type: DATA_ACTION.UPGRADE_ANONYMOUS_USER_FINISHED,
					payload: {
						user: firebaseApp.auth().currentUser!,
						error: response.code === RESPONSE_CODE.SUCCESS ? null : response,
					},
				})
			} catch (error) {
				console.error(error)
				dispatch({
					type: DATA_ACTION.UPGRADE_ANONYMOUS_USER_FINISHED,
					payload: {
						user: firebaseApp.auth().currentUser!,
						error: {
							code: (error as any).code,
							message: (error as any).message,
						},
					},
				})
			}
		}

		const setSubscriptionPaid = async () => {
			if (state.currentUser) {
				dispatch({
					type: DATA_ACTION.SIGNED_IN,
					payload: {
						user: state.currentUser,
						isPro: true,
						isWeb: true,
					},
				})
			}
		}

		const contextValue: DataContext = {
			...state,
			/* upgradeAnonymousUser, */
			setSubscriptionPaid,
		}

		return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
	}

	const useAuthContext = (): DataContext => {
		return useContext<DataContext>(AuthContext)
	}

	return { AuthContext, AuthProvider, useAuthContext }
}

export const { AuthContext, AuthProvider, useAuthContext } = createAuthContext(reducer, initialState)
