import IosIcon from "@assets/Ios.svg"
import IosQR from "@assets/IosQR.png"
import Screenshot from "@assets/Screenshot.png"
import { ScreenLayout } from "@components/layouts"
import { Trans } from "@lingui/macro"
import { useWindowLayout } from "~/hooks/use-window-layout"

import {
	Wrapper,
	DownloadWrapper,
	ScreenshotWrapper,
	DownloadContent,
	Block,
	BlockNumber,
	DownloadButton,
	Line,
	Email,
	Sub,
	Appstore,
	QR,
	FooterWrapper,
	Title,
	Description,
	Flexed,
	MobileDownloadButtons,
	DesktopDownloadButtons,
} from "./download-app-screen.styles"

export interface IDownloadAppScreenProps {
	onNext?: () => void
	onPrev?: () => void
}

export const DownloadAppScreen = (props: IDownloadAppScreenProps) => {
	const size = useWindowLayout()

	return (
		<ScreenLayout size={size}>
			<Wrapper>
				<DownloadWrapper>
					<DownloadContent size={size}>
						<Flexed direction="column" width="50%">
							<Block size={size}>
								<BlockNumber>1</BlockNumber>
								<Title size={size}>
									<Trans>Download the App</Trans>
								</Title>
								<Description size={size}>
									<Trans>Follow the link to download Pora now or check your inbox.</Trans>
								</Description>
								{size === "Mobile" && (
									<MobileDownloadButtons direction="column" justify="flex-start" align="center">
										<DownloadButton size={size} href="https://pora-ai.onelink.me/vqqJ/8rtgmxv5">
											<span>
												<img alt="" src={IosIcon} />
												<Sub>
													<Trans>Download on the</Trans>
												</Sub>
												<Appstore>
													<Trans>App Store</Trans>
												</Appstore>
											</span>
										</DownloadButton>
									</MobileDownloadButtons>
								)}
								{size === "Desktop" && (
									<DesktopDownloadButtons>
										<Flexed direction="row" justify="space-between">
											<Flexed direction="column" align="flex-start" width="50%">
												{/* FIXME */}
												{/* <QR alt="" src={IosQR} /> */}
												<DownloadButton size={size}>
													<span>
														<img alt="" src={IosIcon} />
														<Sub>
															<Trans>Download on the</Trans>
														</Sub>
														<Appstore>
															<Trans>App Store</Trans>
														</Appstore>
													</span>
												</DownloadButton>
											</Flexed>
										</Flexed>
									</DesktopDownloadButtons>
								)}
							</Block>
							<Block size={size}>
								<Line />
								<BlockNumber>2</BlockNumber>
								<Title size={size}>
									<Trans>Enter</Trans> <Email>{localStorage.getItem("email")}</Email>
									<br />
									<Trans>Email in the App</Trans>{" "}
								</Title>
								{/* <Description size={size}>
									<Trans>You should enter email that you wrote before.</Trans>
								</Description> */}
							</Block>
							<Block size={size}>
								<Line />
								<BlockNumber>3</BlockNumber>
								<Title size={size}>
									<Trans>Enjoy your experience</Trans>
								</Title>
								<Description size={size}>
									<Trans>Your personal course with hundreds of exercises and articles is waiting for you!</Trans>
								</Description>
							</Block>
						</Flexed>
						{size === "Desktop" && (
							<ScreenshotWrapper>
								<img alt="" src={Screenshot} />
							</ScreenshotWrapper>
						)}
					</DownloadContent>
					<FooterWrapper />
				</DownloadWrapper>
			</Wrapper>
		</ScreenLayout>
	)
}
