import { AnalyticsPlugin } from "analytics"
import type { AdvancedMatching, Options } from "react-facebook-pixel"
import { AnalyticsEventsMap } from "~/services/analytics/analytics"
import { GTagEventsMap } from "~/services/analytics/gtag"
import { initTiktokPixel } from "~/services/analytics/tiktok-pixel-initializer"
import { trackMapped } from "~/services/analytics/wrapper"

export type TiktokPixelConfig = {
	pixelId: string
}
export function tiktokPixelPlugin(config: TiktokPixelConfig): AnalyticsPlugin {
	return {
		name: "tiktokPixel",
		config: { pixelId: config.pixelId },
		initialize: async ({ config: { pixelId } }: any) => {
			initTiktokPixel(pixelId)
		},
		page: () => {
			window.ttq.page()
		},
		track: ({ payload: { event, properties } }: any) => {
			window.ttq.track(event, properties)
		},
		identify: ({ payload }: { payload: { traits: Record<string, unknown> } }) => {
			if (payload.traits) {
				window.ttq.identify(payload.traits)
			}
		},
		loaded: () => window.ttq !== undefined,
	}
}

declare global {
	interface Window {
		ttq: any
		page: () => void
	}
}
