import { defineMessage, t, Trans } from "@lingui/macro"
import { useMemo } from "react"
import { i18nCustom } from "~/services/service/i18n-service"
import { StripeSubscriptionPriceInfo } from "~/subscriptions-web/server-api"
import { Price, PriceBubble, PriceLength, PriceOldSmall, PriceSale, PriceTitle, PriceValue } from "../styles"

interface PriceAfterTrialProps {
	paywallPrice: StripeSubscriptionPriceInfo | null
	paywallWeeklyPrice: () => string | undefined
	isSpecialOffer?: boolean
}

export function PriceAfterTrialWeekly({ paywallWeeklyPrice, paywallPrice, isSpecialOffer }: PriceAfterTrialProps) {
	if (paywallPrice && paywallPrice.interval) {
		defineMessage({ message: paywallPrice.interval.charAt(0).toUpperCase() + paywallPrice.interval.slice(1) })
		defineMessage({ message: "Your" })
		defineMessage({ message: "Daily" })
	}

	// console.log("___PAYWALL PRICE___", paywallPrice)

	const planInterval = useMemo(() => {
		if (paywallPrice) {
			if (paywallPrice.interval === "day" && paywallPrice.intervalCount === 1) {
				return i18nCustom._("Daily") + " "
			} else {
				return `${paywallPrice?.intervalCount}-${
					paywallPrice &&
					paywallPrice.interval &&
					i18nCustom._(paywallPrice?.interval.charAt(0).toUpperCase() + paywallPrice?.interval.slice(1))
				}${" "}`
			}
		} else {
			return i18nCustom._("Your") + " "
		}
	}, [paywallPrice])

	let interval = paywallPrice?.interval
	let price = paywallPrice ? paywallPrice.amount / 100 : 0
	if (interval === "month") {
		interval = "week"
		price = Math.round((price / 61) * 7 * 100) / 100
	}

	return (
		<PriceBubble padding="0" margin="40px 0 12px">
			<PriceTitle>
				<Trans>Price after trial</Trans>
			</PriceTitle>
			<Price>
				<span>
					<PriceLength>
						{planInterval}
						<Trans>Plan</Trans>
					</PriceLength>
					<PriceSale isSale>
						{isSpecialOffer ? (
							<Trans>Special offer</Trans>
						) : (
							<>
								34% <Trans>off</Trans>
							</>
						)}
					</PriceSale>
				</span>
				<span>
					<PriceValue>
						{paywallPrice?.currency === "usd" && "$"}
						{price} / {interval}
					</PriceValue>
					<PriceOldSmall>
						<Trans>Billed at</Trans>: ${(paywallPrice?.amount ?? 0) / 100}
					</PriceOldSmall>
				</span>
			</Price>
		</PriceBubble>
	)
}
