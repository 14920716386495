import { Trans, t } from "@lingui/macro"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Button } from "~/components2/atoms/Button"
import { PrivacyPolicyLink, TermsAndConditionsLink } from "~/components2/atoms/copyrights"
import { Flexed } from "~/components2/atoms/Flexed"
import { Header } from "~/components2/atoms/Header"
import { Input } from "~/components2/atoms/Input"
import { TextBody, TextHead } from "~/components2/atoms/Text"
import { amplitudeService } from "~/services/amplitude"
import { ProgressStatus } from "~/subscriptions-web/hooks/progress-status"
import { useSendSignInOTP } from "~/subscriptions-web/hooks/use-send-signin-otp"

export interface Props {
	onNext?: () => void
	onPrev?: () => void
}

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background-color: #f6f6f6;
	box-sizing: border-box;

	z-index: 50;
`

const ConfirmationWrapper = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	padding-bottom: 44px;
	box-sizing: border-box;

	transition: left 0.4s;
	top: 64px;
	width: 100%;
	height: calc(100% - 64px);
	overflow-y: scroll;

	@media (orientation: landscape) {
		justify-content: flex-start;
	}
`

const Content = styled.div`
	position: relative;
	width: 100%;
	height: auto;

	padding: 0 20px;

	display: flex;
	flex-direction: column;
	align-items: center;

	box-sizing: border-box;

	> p {
		margin: 24px 12px;
		text-align: center;
	}
`

const Subtitle = styled.div`
	position: relative;
	margin-top: 0;

	> p {
		margin-top: -12px;
	}
`

const ButtonWrapper = styled.div`
	width: 100%;
	padding: 0 20px;
	margin-top: 20px;

	box-sizing: border-box;

	> button {
		width: 100%;
		margin: 0;
	}

	@media (orientation: landscape) {
		position: relative;
		bottom: 0;
		left: 0;
		margin: 0;
	}
`

const LowerText = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	padding: 0 20px;

	box-sizing: border-box;

	@media (max-width: 320px) {
		bottom: -60px;
	}

	> div {
		:first-child {
			margin-bottom: 40px;

			@media (max-width: 320px) {
				margin-bottom: 0;
			}
		}
	}
`

const Description = styled.div`
	width: 100%;

	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.03em;

	color: #828691;

	@media (max-width: 320px) {
		font-size: 13px;
		line-height: 20px;
	}
`

export const SignInEmailScreen = (props: any) => {
	// const {
	//     not_signed_in,
	//     anonymous_not_subscribed,
	//     anonymous_subscribed,
	//     not_verified_subscribed,
	//     //verified, //- rest value, we use as default case
	// } = useUserStatus();
	//const { isUserSignedIn, isPro, currentUser } = useAuthContext();
	const { sendSignInOTP, sendSignInOTPState } = useSendSignInOTP(props.onNext)

	const isControlsDisabled = sendSignInOTPState.status === ProgressStatus.WORKING

	// const errorMessage =
	// 	sendSignInOTPState.response?.code !== RESPONSE_CODE.OTP_SENT ? sendSignInOTPState.response?.message : ""

	// const { isUserEmailUpdating, userEmailUpdatingError, upgradeAnonymousUser } = useUpgradeAnonymousUser(props.onNext)
	const [email, setEmail] = useState("")

	const [validEmail, setValidEmail] = useState(true)

	const confirmationRef = useRef<HTMLDivElement>(null)
	// const downloadRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		setEmail(props.currentEmail)
		// checkIfValid(email)
	}, [props.currentEmail])

	useEffect(() => {
		amplitudeService.logEvent("[All] Check email")
	}, [])

	const requestOTPLink = async (event: any) => {
		event.preventDefault()

		localStorage.setItem("email", email)
		// const email = event.target.elements.emailElement.value
		await sendSignInOTP(email, "LINK", "/signin-callback")
	}
	// const onClickConfirm = () => {
	// 	if (confirmationRef && confirmationRef.current && downloadRef && downloadRef.current) {
	// 		confirmationRef.current.style.left = "-100%"
	// 		downloadRef.current.style.display = "flex"
	// 		setTimeout(() => {
	// 			if (downloadRef && downloadRef.current) downloadRef.current.style.left = "0%"
	// 		}, 0)
	// 	}
	// }

	const checkIfValid = (mail: string) => {
		const validation = /^\S+@\S+\.\S+$/

		if (mail && validation.test(mail)) {
			setValidEmail(true)
		} else {
			setValidEmail(false)
		}
	}

	const onChangeEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const mail = evt.currentTarget.value

		checkIfValid(mail)

		setEmail(mail)
	}

	// const confirmEmail = async (event: any) => {
	// 	event.preventDefault()
	// 	const newEmail = event.target.elements.emailElement.value as string
	// 	await upgradeAnonymousUser(newEmail)
	// 		.then(() => {
	// 			amplitudeService.logEvent("[All] Button | Confirm email")
	// 		})
	// 		.catch(() => {
	// 			console.log("email confirmation failed")
	// 		})
	// }

	return (
		<Wrapper>
			<Header lightBurger desktopSimplified />
			<ConfirmationWrapper ref={confirmationRef}>
				<form id="email-form" onSubmit={requestOTPLink}>
					<Content>
						<TextHead size="small">
							<Trans>Welcome to Pora!</Trans>
							<br />
							<Trans>Enter your email to sign in</Trans>
						</TextHead>
						<Subtitle>
							<TextBody size="big" color="#828691">
								<Trans>Your email adress</Trans>
							</TextBody>
						</Subtitle>
						{/* <InputMask mask="/\d{3} \d{3} \d{4}/" value={email} onChange={onChangeEmail}>
						{(inputProps: any) => <Input
							{...inputProps}
							value={email}
							type="email"
							placeholder="youremail@gmail.com"
							onChange={onChangeEmail}
							validationMessage={!validEmail && email.length > 0 ? "Enter the correct email" : undefined}
						/>}
					</InputMask> */}
						<Input
							name="emailElement"
							type="email"
							placeholder="your@email.com"
							// defaultValue={props.currentEmail}
							value={email}
							onChange={onChangeEmail}
							validationMessage={!validEmail && email.length > 0 ? t`Enter the correct email` : undefined}
						/>
					</Content>
					{/*{props.onNext && (*/}
					<ButtonWrapper>
						<Button
							type="submit"
							disabled={isControlsDisabled}
							title={isControlsDisabled ? t`Sending...` : t`Send link to signIn`}
						/>
					</ButtonWrapper>
					{/*)}*/}
				</form>
				<Flexed.Spacer />
				<LowerText>
					<Description>
						<Trans>By clicking “See my Results” below you agree to Pora’s</Trans> <TermsAndConditionsLink />{" "}
						<Trans>and</Trans>
						<PrivacyPolicyLink />.
					</Description>
				</LowerText>
			</ConfirmationWrapper>

			{/*{!!userEmailUpdatingError ? userEmailUpdatingError.message : null}*/}
		</Wrapper>

		// <div>
		//     SIGN IN PAGE
		//     <br />
		//     <br />
		//     <form id="email-form" onSubmit={requestOTPLink}>
		//         <input name="emailElement" type="email" placeholder="sasha@gmail.com" />
		//         <br />
		//         <br />
		//         <button
		//             type='submit'
		//             disabled={isControlsDisabled}
		//         >
		//             {
		//                 isControlsDisabled
		//                     ? <>Sending...</>
		//                     : <>Send link to signIn</>
		//             }
		//         </button>
		//     </form>
		//     {
		//         !!errorMessage && errorMessage
		//     }
		// </div>
	)
}
